import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { MainserviceService } from 'src/app/mainservice.service';

@Component({
  selector: 'app-nicu-dashboard',
  templateUrl: './nicu-dashboard.component.html',
  styleUrls: ['./nicu-dashboard.component.css']
})
export class NicuDashboardComponent implements OnInit {
  
  vitalSignDashboard :any = {
    MDC_PULS_OXIM_SAT_O2 : 0,
    MDC_ECG_HEART_RATE : 0 , 
    MDC_PULS_OXIM_PLETH_RESP_RATE : 0,
    LASTUPDATEDATETIME : '-',
    nibp_sys : 0,
    nibp_dia : 0,
    prvalue : 0
  }
  constructor(private route: ActivatedRoute, public mainserviceService: MainserviceService) { }

  ngOnInit(): void {
    setInterval(()=>{
      this.fetchRealTimeVitalSignfromeMac();
    },10000)
  }


  fetchRealTimeVitalSignfromeMac(){

    const requestPayload = {
      patientid : 1
    }
    this.mainserviceService.fetchICUData(requestPayload).subscribe((responseGetICUData) => {
      console.log("response get icu data",responseGetICUData);

      if(responseGetICUData && responseGetICUData.status){
        let data = responseGetICUData.data;

          this.vitalSignDashboard = {
            MDC_PULS_OXIM_SAT_O2 : data[0].MDC_PULS_OXIM_SAT_O2,
            MDC_ECG_HEART_RATE : data[0].MDC_ECG_HEART_RATE, 
            MDC_PULS_OXIM_PLETH_RESP_RATE : data[0].MDC_TTHOR_RESP_RATE,
            nibp_sys : 0,
            nibp_dia : 0,
            prvalue : 0,
            LASTUPDATEDATETIME : moment(data[0].LASTUPDATEDATETIME).format('DD/MM/yyyy hh:mm A')
          }
      }
    });

  }
}
