import { RouteInfo } from './sidebar.metadata';

let menu = [
  {
    path: '/birdmeditechdashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/mastermanagement',
    title: 'User Master',
    icon: 'mdi mdi-account',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/overview',
    title: 'Overview',
    icon: 'mdi mdi-chart-line',
    class: '',
    extralink: false,
    submenu: []
  }
]

let menu1 = [
  {
    path: '/customerservicedashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/machinemaster',
    title: 'Create Machine',
    icon: 'mdi mdi-settings',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/clientmaster',
    title: 'Create Client',
    icon: 'mdi mdi-account',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/site',
    title: 'Create Site',
    icon: 'mdi mdi-pharmacy',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/assign-machine',
    title: 'Site Machine Mapping',
    icon: 'mdi mdi-settings',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/complaintstatus',
    title: 'Complaint Status',
    icon: 'mdi mdi-information',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/amcstatus',
    title: 'Amc Status',
    icon: 'mdi mdi-information',
    class: '',
    extralink: false,
    submenu: []
  }
]

let menu2 = [
  {
    path: '/managerdashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/clientdetails',
    title: 'Client Details',
    icon: 'mdi mdi-account',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/complaintstatus',
    title: 'Complaint Status',
    icon: 'mdi mdi-information',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/amcstatus',
    title: 'Amc Status',
    icon: 'mdi mdi-information',
    class: '',
    extralink: false,
    submenu: []
  }
]

let menu3 = [
  {
    path: '/fieldengdashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/sitesettingdetails',
    title: 'Site Settings',
    icon: 'mdi mdi-settings',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/complaintstatus',
    title: 'Complaint Status',
    icon: 'mdi mdi-information',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/amcstatus',
    title: 'Amc Status',
    icon: 'mdi mdi-information',
    class: '',
    extralink: false,
    submenu: []
  }
]

let menu4 = [
  {
    path: '/vitalclientdashboard',
    title: 'Vital Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/clientuser',
    title: 'Client User',
    icon: 'mdi mdi-account',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/complaintstatus',
    title: 'Complaint Status',
    icon: 'mdi mdi-information',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/amcstatus',
    title: 'Amc Status',
    icon: 'mdi mdi-information',
    class: '',
    extralink: false,
    submenu: []
  }
]

let menu5 = [
  {
    path: '/vitallocationdashboard',
    title: 'Vital Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/locationadmin',
    title: 'User Master',
    icon: 'mdi mdi-account',
    class: '',
    extralink: false,
    submenu: []
  },

  {
    path: '/complaintstatus',
    title: 'Complaint Status',
    icon: 'mdi mdi-information',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/amcstatus',
    title: 'Amc Status',
    icon: 'mdi mdi-information',
    class: '',
    extralink: false,
    submenu: []
  }
]

let menu6 = [
  {
    path: '/clientwarmerstatus',
    title: 'CNS Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/vital-monitors',
    title: 'Vitals Monitor',
    icon: 'mdi mdi-chart-line',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/alarmanalytics',
    title: 'Alert Log',
    icon: 'mdi mdi-chart-pie',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/doctors-panel',
    title: 'Doctors Deck',
    icon: 'mdi mdi-account-star-variant',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/nursepanel',
    title: 'Nurses Deck',
    icon: 'mdi mdi-account-star-variant',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/lab-test',
    title: 'Lab Test',
    icon: 'mdi mdi-pharmacy',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/eventlogs',
    title: 'Event Tracker',
    icon: 'mdi mdi-pharmacy',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/dischargereport',
    title: 'Discharge Report',
    icon: 'mdi mdi-file-outline',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admission-note',
    title: 'Admission Note',
    icon: 'mdi mdi-account-card-details',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/babymanager',
    title: 'Current Admissions',
    icon: 'mdi mdi-baby',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/babydischarge',
    title: 'Discharge History',
    icon: 'mdi mdi-baby',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Master',
    icon: 'mdi  mdi-account',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/controlroommanager',
        title: 'User Master',
        icon: 'mdi mdi-account',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/medicinelist',
        title: 'Medication Master',
        icon: 'mdi mdi-pharmacy',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/dischargemaster',
        title: 'Treatment Master',
        icon: 'mdi mdi-baby',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admission-master',
        title: 'Admission Master',
        icon: 'mdi mdi-chart-line',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  // {
  //   path: '',
  //   title: 'Admin',
  //   icon: 'mdi mdi-television-guide',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/complaintstatus',
  //       title: 'Complaint Status',
  //       icon: 'mdi mdi-comment-text-outline',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/amcstatus',
  //       title: 'Amc Status',
  //       icon: 'mdi mdi-information',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // }
]

let menu7 = [
  {
    path: '/clientwarmerstatus',
    title: 'CNS Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/vital-monitors',
    title: 'Vitals Monitor',
    icon: 'mdi mdi-chart-line',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/alarmanalytics',
    title: 'Alert Log',
    icon: 'mdi mdi-chart-pie',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/doctors-panel',
    title: 'Doctors Deck',
    icon: 'mdi mdi-account-star-variant',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/nursepanel',
    title: 'Nurses Deck',
    icon: 'mdi mdi-account-star-variant',
    class: '',
    extralink: false,
    submenu: []
  }
]

let menu8 = [
  {
    path: '/clientwarmerstatus',
    title: 'CNS Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/vital-monitors',
    title: 'Vitals Monitor',
    icon: 'mdi mdi-chart-line',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/alarmanalytics',
    title: 'Alert Log',
    icon: 'mdi mdi-chart-pie',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/doctors-panel',
    title: 'Doctors Deck',
    icon: 'mdi mdi-account-star-variant',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/nursepanel',
    title: 'Nurses Deck',
    icon: 'mdi mdi-account-star-variant',
    class: '',
    extralink: false,
    submenu: []
  }
]

export const ROUTES: RouteInfo[] = [];
let usercatid = JSON.parse(sessionStorage.getItem("userInfo1")) == null ? 0 : JSON.parse(sessionStorage.getItem("userInfo1")).usercategoryid;

// console.log("User cat =>", usercatid);


if (usercatid == 0) {
  // console.log("Something wrong");
  sessionStorage.setItem("userInfo1", JSON.stringify({
    "usermasterid": 1,
    "usermastername": "test",
    "address": "test",
    "state": 21,
    "city": 323,
    "pincode": 411019,
    "emailid": "test@gmail.com",
    "mobilenumber": 7896543210,
    "username": "test@1234",
    "usercategoryid": 1,
    "usercategoryname": "",
    "prefix": "",
    "token": "294e9dddf5696c5ea3a1fb4e238d1212718861c05eb1ebb74cf80a363560cacfe9cdc83518bfa4f26d2641fe0c00f7d356dfd13ee9c5bc0e5015dba6192d82bd4dc09c9b40573b3a4da5af99ee5c9eb641e4aaf8936a1eab1a62538bc2c918e6dc10dd6c3ccb8ce00807f990edd21556b1be26d1b792b81e105d4bc5800aa059f178f3d9bee07f5294d6602d09b1ed961d3e7af417fdb606c078ba9ffaf9ebabb7e6fd9013f2d1777b61ebcb32d4afa8e721c9e0164ea73346657e93b02544e864d8ae389980e7274aaac21241211b6b30247c6025a2ced84a82cb24aaf9cc59",
    "cloudsecretuser": "#Q^LP3@8le$df^8#"
  }));
} else if (usercatid == 1) {
  for (let i = 0; i < menu.length; i++) {
    ROUTES.push(menu[i])
  }
} else if (usercatid == 2) {
  for (let i = 0; i < menu1.length; i++) {
    ROUTES.push(menu1[i])
  }
} else if (usercatid == 3) {
  for (let i = 0; i < menu2.length; i++) {
    ROUTES.push(menu2[i])
  }
} else if (usercatid == 4) {
  for (let i = 0; i < menu3.length; i++) {
    ROUTES.push(menu3[i])
  }
} else if (usercatid == 5) {
  for (let i = 0; i < menu4.length; i++) {
    ROUTES.push(menu4[i])
  }
} else if (usercatid == 6) {
  for (let i = 0; i < menu5.length; i++) {
    ROUTES.push(menu5[i])
  }
} else if (usercatid == 7) {
  for (let i = 0; i < menu6.length; i++) {
    ROUTES.push(menu6[i])
  }
} else if (usercatid == 8) {
  for (let i = 0; i < menu7.length; i++) {
    ROUTES.push(menu7[i])
  }
} else if (usercatid == 9) {
  for (let i = 0; i < menu8.length; i++) {
    ROUTES.push(menu8[i])
  }
}




