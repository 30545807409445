<!-- <div class="card login-form ">
	<div class="card-body">
		<h3 class="card-title text-center">Reset password</h3>
		
		<div class="card-text">
			<form>
				<div class="form-group">
					<label for="exampleInputEmail1">Enter your email address and we will send you a link to reset your password.</label>
					<input type="email" class="form-control form-control-sm" placeholder="Enter your email address">
				</div>

				<button type="submit" class="btn btn-primary btn-block">Send password reset email</button>
			</form>
		</div>
	</div>
</div> -->


<div class="d-flex flex-wrap justify-content-around align-items-baseline">
    <div>
        <h3 class="card-title text-center">Reset password</h3>
        <form>
            <div class="form-group">
                <label for="email">Enter your email address and we will send you reset password!
                    password.</label>
                <input type="text" class="form-control form-control" placeholder="Enter your email address">
            </div>

            <div class="form-group">
                <label for="username">Username</label>
                <input type="text" class="form-control form-control" placeholder="Enter your username">
            </div>

            <button type="submit" class="btn btn-primary btn-block">Reset Password</button>
        </form>
    </div>
</div>