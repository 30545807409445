import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MainserviceService } from 'src/app/mainservice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';

export interface AdmissionMasterSchema {
  'admission_master_id': number,
  'field_master_key': string,
  'field_master_value': string,
  'createddate': any,
  'isactive': any
}

@Component({
  selector: 'app-admission-master',
  templateUrl: './admission-master.component.html',
  styleUrls: ['./admission-master.component.css']
})

export class AdmissionMasterComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  admissionFieldMasterData: any[] = [];
  admissionMasterForm: FormGroup;
  activeSubTabIndex: any = 0;
  isEditMode: boolean = false;
  isEditData: any;
  displayedColumns: string[] = ['serialno', 'field_master_value', 'createddate', 'action'];
  admissionMasterData: AdmissionMasterSchema[] = [];

  dataSource = new MatTableDataSource<AdmissionMasterSchema>(this.admissionMasterData);

  pageSize: number = 20;


  constructor(private httpService: MainserviceService,
    private formbuilder: FormBuilder,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.admissionMasterForm = this.formbuilder.group({
      field_master_key: [null, Validators.required],
      field_master_value: [null, Validators.required]
    })
    this.fetchAdmissionFieldMasterData();
  }

  ngAfterViewInit(): void {
    console.log("pagination is update ========")
    this.dataSource.paginator = this.paginator;
  }

  subTabChanged(tab) {
    console.log("subtabChanged : ", tab);
    this.activeSubTabIndex = tab.index;
    if (this.admissionFieldMasterData && this.admissionFieldMasterData.length > 0) {
      this.fetchAdmissionMaster(this.admissionFieldMasterData[this.activeSubTabIndex].field_master_key);
    }
  }


  patchAdmissionFormData(record) {
    console.log("patch patchAdmissionFormData ");
    console.table(record);
    this.isEditData = record;
    this.isEditMode = true;
    this.admissionMasterForm.patchValue({
      field_master_key: record.field_master_key,
      field_master_value: record.field_master_value
    });

  }



  fetchAdmissionFieldMasterData() {
    console.log("call fetchAdmissionFieldMasterData");
    let { clientid, siteid } = JSON.parse(localStorage.getItem("userInfo"));
    this.httpService.fetchAdmissionFieldMaster({
      "clientid": clientid,
      "siteid": siteid
    }).pipe(catchError(err => {
      //after getting error call error notifier - pending
      return of(null);
    })).subscribe(resFetchAdmissionFieldMaster => {

      if (resFetchAdmissionFieldMaster && resFetchAdmissionFieldMaster.status_code === 's_407') {
        this.admissionFieldMasterData = resFetchAdmissionFieldMaster.data;
        console.log("resFetchAdmissionFieldMaster ==", this.admissionFieldMasterData);
        if (this.admissionFieldMasterData && this.admissionFieldMasterData.length > 0) {

          this.fetchAdmissionMaster(this.admissionFieldMasterData[0].field_master_key);
        }

      } else {
        this.admissionFieldMasterData = [];
      }
    })
  }

  onSubmitAdmissionMaster() {

    let { clientid, siteid } = JSON.parse(localStorage.getItem("userInfo"));

    let requestPayload = {
      field_master_key: this.admissionMasterForm.value.field_master_key,
      field_master_value: this.admissionMasterForm.value.field_master_value,
      clientid: clientid,
      siteid: siteid
    }
    console.table(requestPayload);

    this.httpService.createAdmissionMaster(requestPayload).pipe(catchError(err => {
      return of(null);
    })).subscribe(resAdmissionMaster => {
      if (resAdmissionMaster && resAdmissionMaster.status_code === 's_402') {
        if (this.admissionFieldMasterData && this.admissionFieldMasterData.length > 0) {
          this.fetchAdmissionMaster(this.admissionFieldMasterData[this.activeSubTabIndex].field_master_key);
          this.resetForm(this.admissionMasterForm);
          // pop-up the success message 
          this.toastr.success('Admission master added succesfully!', '');
        }

      } else {
        // pop-up the error message 
        this.showerr("Something wrong! kindly contact to system admin.")
      }
    })
  }

  showerr(errName) {
    this.toastr.error(errName, '', {
      timeOut: 2000,
    });
  }

  resetForm(form: FormGroup) {
    this.isEditData = null;
    this.isEditMode = false;
    form.reset();
    form.get('field_master_key').setErrors(null);
    form.get('field_master_value').setErrors(null);
    form.get('field_master_key').markAsPristine();
    form.get('field_master_value').markAsPristine();

  }

  onUpdateAdmissionMaster() {
    console.log("call updateAdmissionMaster", this.isEditData);
    console.table(this.admissionMasterForm);

    let requestPayload = {
      admission_master_id: this.isEditData.admission_master_id,
      field_master_key: this.admissionMasterForm.value.field_master_key,
      field_master_value: this.admissionMasterForm.value.field_master_value,
    }


    this.httpService.updateAdmissionMaster(requestPayload).pipe(catchError(err => {
      return of(null);
    })).subscribe(resAdmissionMaster => {

      console.table(resAdmissionMaster);

      if (resAdmissionMaster && resAdmissionMaster.status_code === 's_403') {
        if (this.admissionFieldMasterData && this.admissionFieldMasterData.length > 0) {
          this.fetchAdmissionMaster(this.admissionFieldMasterData[this.activeSubTabIndex].field_master_key);
          this.resetForm(this.admissionMasterForm);
          // pop-up the success message 
          this.toastr.success('Admission master updated succesfully!', '');
        }

      } else {
        // pop-up the error message 
        this.showerr("Something wrong! kindly contact to system admin.")
      }
    })
  }

  fetchAdmissionMaster(master_key: string) {
    console.log("call fetchAdmissionMaster");
    this.admissionMasterData = [];
    console.log("paginator defination ========", this.paginator);

    this.dataSource = new MatTableDataSource<AdmissionMasterSchema>(this.admissionMasterData);
    this.dataSource.paginator = this.paginator;

    let { clientid, siteid } = JSON.parse(localStorage.getItem("userInfo"));

    let requestPayload = {
      field_master_key: master_key,
      clientid: clientid,
      siteid: siteid
    }
    this.httpService.fetchAdmissionMaster(requestPayload).pipe(catchError(err => {
      return of(null);
    })).subscribe(resAdmissionMaster => {

      if (resAdmissionMaster && resAdmissionMaster.status_code === 's_407') {

        console.log("resAdmissionMaster ", resAdmissionMaster.data)

        this.admissionMasterData = resAdmissionMaster.data.map((record, index) => {
          return {
            'serialno': index += 1,
            'admission_master_id': record.admission_master_id,
            'field_master_key': record.field_master_key,
            'field_master_value': record.field_master_value,
            'createddate': moment(record.createddate).format("DD/MM/yyyy"),
            'defaultstatus': record.defaultstatus,
            'isactive': record.isactive
          }
        });

        this.dataSource = new MatTableDataSource<AdmissionMasterSchema>(this.admissionMasterData);
        this.dataSource.paginator = this.paginator;

        console.table(this.dataSource);
      } else {
        this.admissionMasterData = [];
      }
    })
  }

  deleteAdmissionMasterRecord(id: number) {
    console.log("call deleteAdmissionMaster", id);

    let requestPayload = {
      admission_master_id: id
    }

    console.table(requestPayload);

    this.httpService.deleteAdmissionMaster(requestPayload).pipe(catchError(err => {
      return of(null);
    })).subscribe(resAdmissionMaster => {

      if (resAdmissionMaster && resAdmissionMaster.status_code === 's_404') {

        if (this.admissionFieldMasterData && this.admissionFieldMasterData.length > 0) {
          this.fetchAdmissionMaster(this.admissionFieldMasterData[this.activeSubTabIndex].field_master_key);
          // pop-up the delete message 
          this.toastr.success('Admission master record deleted!', '');
        }
      } else if (resAdmissionMaster && resAdmissionMaster.status_code === 's_408') {
        // somethig wrong message pop-up over the screen
        this.showerr("Something wrong! kindly contact to system admin.")
      }
    })
  }



}
