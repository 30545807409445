<div class="card card-body">
    <div class="card-title">Admission Master</div>
    <form [formGroup]="admissionMasterForm"
        (ngSubmit)="isEditMode ? onUpdateAdmissionMaster() : onSubmitAdmissionMaster()">
        <div class="row">
            <div class="col-sm-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Admission Field:*</mat-label>
                    <mat-select name="admission-field" formControlName="field_master_key">
                        <mat-option *ngFor="let record of admissionFieldMasterData"
                            [value]="record.field_master_key">{{record.field_master_value}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-3">
                <mat-form-field class="example-full-width" appearance="outline" class="w-100">
                    <mat-label>Master Value:*</mat-label>
                    <input matInput autocomplete="off" type="text" formControlName="field_master_value">
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <ng-container>
                    <button class="btn btn-success btn-lg mt-1" type="submit" [disabled]="!admissionMasterForm.valid">
                        <span><i
                                [class]="{ 'mdi' : true ,'mdi-content-save-settings' : isEditMode , 'mdi-plus' : !isEditMode  }"></i></span>
                    </button>
                    <button class="btn btn-danger btn-lg mt-1" type="reset">
                        <span><i class="mdi mdi-refresh"></i></span>
                    </button>
                    <!-- <button class="btn btn-info btn-lg mt-1">
                        <span><i class="mdi mdi-file-excel"></i></span>
                    </button> -->
                </ng-container>
            </div>
        </div>
    </form>
    <!--admission form tab view-->
    <mat-tab-group (selectedTabChange)="subTabChanged($event)">
        <mat-tab *ngFor="let record of admissionFieldMasterData" [label]="record.field_master_value">
            <!--*ngIf="activeSubTabIndex == 0"-->
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!-- serialno Column -->
                <ng-container matColumnDef="serialno">
                    <th mat-header-cell *matHeaderCellDef> Serial No</th>
                    <td mat-cell *matCellDef="let element"> {{element.serialno}} </td>
                </ng-container>

                <!-- field_master_value Column -->
                <ng-container matColumnDef="field_master_value">
                    <th mat-header-cell *matHeaderCellDef> Field Value</th>
                    <td mat-cell *matCellDef="let element"> {{element.field_master_value}} &nbsp; <span *ngIf = "element.defaultstatus" class="badge badge-pill badge-success">Default</span> </td>
                </ng-container>

                <!-- createddate Column -->
                <ng-container matColumnDef="createddate">
                    <th mat-header-cell *matHeaderCellDef> Created Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.createddate}} </td>
                </ng-container>


                <!-- action Column -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container>

                            <span (click)="patchAdmissionFormData(element)" class="material-icons editicon"
                                style="cursor: pointer;">
                                edit
                            </span>
                            <span (click)="deleteAdmissionMasterRecord(element.admission_master_id)"
                                class="material-icons deleteicon ml-2" style="cursor: pointer;">
                                delete
                            </span>

                        </ng-container>
                    </td>
                </ng-container>

                <tr class="matheaderrow" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div class="container mt-4 text-center" *ngIf="dataSource?.data.length === 0">
                <div class="no-records-icon">
                    <i class="fas fa-exclamation-triangle"></i> 
                </div>
                <h1>No Records Found</h1>
            </div>


            <mat-paginator *ngIf="dataSource?.data.length > 0" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
            </mat-paginator>
        </mat-tab>
    </mat-tab-group>
</div>