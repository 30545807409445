import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
 
  constructor() {
    // document.body.style.zoom = '0.8'.toString(); 
  }

  ngOnInit() {

  }


}
