import { Component, HostListener, OnInit } from '@angular/core';
import { Observable, fromEvent, merge, interval } from 'rxjs';
import { switchMap, take, tap, skipWhile } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'app';
  constructor() {
    //localStorage.removeItem("userInfo");
  }

  ngOnInit(): void {


  }





}
