import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../environments/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class MainserviceService {

  baseUrl = environment.serviceUrl;
  constructor(private http: HttpClient) { }

  post(data, url) {
    console.log(data, url);
    return this.http.post(this.baseUrl + url, data);
  }

  getcountry(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/address/getcountry', data);
  }

  getstate(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/address/getstate', data);
  }

  getdist(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/address/getdistrict', data);
  }

  loginUserMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/usermaster/login', data);
  }

  loginUser(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/usermaster/login', data);
  }

  getUser(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/usermaster/get', data);
  }

  getApplog(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/applog/get', data);
  }

  getUserDetails(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/usermaster/getclientbyuser', data);
  }

  getMedicinecategory(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medicinecategory/get', data);
  }


  createUser(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/usermaster/create', data);
  }

  updateUser(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/usermaster/update', data);
  }

  createMachine(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/machine/create', data);
  }

  updateMachine(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/machine/update', data);
  }

  getMachine(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/machine/get', data);
  }

  deleteMachine(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/machine/delete', data);
  }

  createClientMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/clientmaster/create', data);
  }

  getClientMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/clientmaster/get', data);
  }

  updateClient(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/clientmaster/update', data);
  }

  deleteClient(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/clientmaster/delete', data);
  }

  createDutyDoctor(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/doctormaster/create', data);
  }

  getDutyDoctor(data): Observable<any> {
    return this.http.post(this.baseUrl + "v1/doctormaster/get", data)
  }

  updateDutyDoctor(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/doctormaster/update', data);
  }

  deleteDutyDoctor(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/doctormaster/delete', data);
  }

  createNurseMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/nursemaster/create', data);
  }

  getNurseMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/nursemaster/get', data);
  }

  updateNurseMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/nursemaster/update', data);
  }

  deleteNurseMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/nursemaster/delete', data);
  }


  createSite(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/site/create', data);
  }

  getSite(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/site/get', data);
  }

  getSitebyuser(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/site/getbyuser', data);
  }

  updateSite(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/site/update', data);
  }

  deleteSite(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/site/delete', data);
  }

  getAlarmDetails(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/alarmmaster/get', data);
  }

  createMedicinecategory(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medicinecategory/create', data);
  }

  createdrugmaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/drugmaster/create', data);
  }

  createwhenmedcinemaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medicinedetails/createwhen', data);
  }

  createhowmaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medicinedetails/createhow', data);
  }

  createfrequencymaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medicinedetails/createfrequency', data);
  }

  createdosageunitmaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medicinedetails/createdosageunit', data);
  }

  getdruglist(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/drugmaster/get', data);
  }

  getbabyid(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanager/get', data);
  }

  getbabybyMRN(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanager/filterbymrn', data);
  }

  fetchBabybyFilter(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanager/fetchBabybyfilter', data);
  }

  getMothersList(req): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/dashboard/getmotherlist`, req);
  }

  getwhenmaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medicinedetails/getwhen', data);
  }

  gethowmaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medicinedetails/gethow', data);
  }

  getfrequency(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medicinedetails/getfrequency', data);
  }

  getdosageunit(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medicinedetails/getdosageunit', data);
  }

  createmedicinemaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medicinemaster/create', data);
  }

  getmedicine(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medicinemaster/get', data);
  }

  fetchMedicine(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medicinemaster/fetch', data);
  }

  getDischargemedicine(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/medication/fetch', data);
  }

  createdutyroaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dutyroaster/create', data);
  }


  getdutyraoster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dutyroaster/get', data);
  }

  deletedutyroaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dutyroaster/delete', data);
  }

  createlocation(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/location/create', data);
  }

  getlocation(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/location/get', data);
  }

  createsite(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/site/create', data);
  }

  getactivealarm(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/alarmlog/getactivealarm', data);
  }
  getAlarms(data: {
    "machineid": Number,
    "clientid": Number,
    "fromdate": moment.Moment,
    "todate": moment.Moment,
    "babyid": Number,
    "mrn": String,
    "type": String,
    "page": Number,
    "pagesize": Number
  }): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/alarmlog/getalerts', data);
  }



  //POST v1/dashboard/getmachinedashboard
  getmachinedetails(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getmachinedashboard', data);
  }

  gettempdata(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/gettemperaturegraph', data);
  }
  //v1/dashboard/getspotwoprgraph
  getspoandpulserate(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getspotwoprgraph', data);
  }


  getspoandpulseratestate(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getspotwoprgraphState', data);
  }


  getalarmanalytic(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/alarmlog/getanalytics', data);
  }

  updatesite(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/site/update', data);
  }

  getamc(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/amcmaster/getamcdetails', data);
  }

  getcomplaint(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/complaint/get', data);
  }

  updatecomplaint(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/complaint/update', data);
  }

  updatecmp(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/complaint/update', data);
  }

  allocatedoctor(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/doctormaster/allocate', data);
  }

  allocatenurse(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/nursemaster/allocate', data);
  }

  getbaby(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanager/get', data);
  }

  getdailybabyreport(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/report/generatebabyvital', data);
  }

  getbbabyhistory(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/report/generatebabyvital', data);
  }

  getdischargereport(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/report/getdischargereport', data);
  }

  getdischargereportclone(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/report/getdischargereportclone', data);
  }


  getdischargesummary(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/report/getdischargesummary', data);
  }

  getdeltatdata(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getdeltatgraph', data);
  }

  getweightdata(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getweightgraph', data);
  }

  getapgardata(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getapgartrendgraph', data);
  }

  getmachineclientwise(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/machine/getmachinebyclient', data);
  }

  getdashboarddetails(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboardUpdate/getstatus', data);
  }

  getdashboarddetailsstatus(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboardUpdate/getbabaycondition', data);
  }

  getstaticdashboard(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getmachinedashboard', data);
  }

  getdashboardupdate(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboardUpdate/get', data);
  }

  getdasboard(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getmachinedashboard', data);
  }
  //POST v1/dashboard/getapgartrendgraph

  getapgarscore(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getapgartrendgraph', data);
  }

  getmasterdashboard(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getmasterdashboard', data);
  }

  getcustomerservicedashboard(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getcustomerservicedashboard', data);
  }

  getfieldengdashboard(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getfieldengineerdashboard', data);
  }

  getmanagerdashboard(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getmanagerdashboard', data);
  }

  getvitaldashboard(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/dashboard/getvitaldashboard', data);
  }

  getmedcinereport(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/report/getmedicinereport', data);
  }

  updateRecentInvestigation(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/recentInvestigation/update', data);
  }

  updateImaging(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/imaging/update', data);
  }

  createTestReport(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/testreport/create', data);
  }

  getTestReport(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/testreport/get', data);
  }

  createDoctorNotes(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/doctornotes/create', data);
  }

  getDoctorNotes(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/doctornotes/get', data);
  }

  createFluidChart(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/fluidbalancechart/create', data);
  }

  getFluidChart(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/fluidbalancechart/get', data);
  }

  createNurseIntervention(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/nurseintervention/create', data);
  }

  getNureseIntervention(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/nurseintervention/get', data);
  }

  createVitalParameter(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/spoprpi/create', data);
  }

  getVitalParameter(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/spoprpi/get', data);
  }

  creatdrughistorynotes(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/drughistorynotes/create', data);
  }

  getdrugHistory(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/drughistorynotes/get', data);
  }

  getDiseaseFilter(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/doctornotes/getfilter', data);
  }

  createReportlog(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/reportlog/create', data);
  }

  createTratmentSummary(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/diseasemaster/create', data);
  }

  getTratmentSummary(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/diseasemaster/get', data);
  }

  deleteTratmentSummary(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/diseasemaster/delete', data);
  }

  updateTratmentSummary(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/diseasemaster/update', data);
  }

  deleteReportLog(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/reportlog/delete', data);
  }

  getBabyReport(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/reportlog/get', data);
  }


  createBabyReport(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanagerupdate/create', data);
  }

  createBabyAdmissionReport(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/report/admission', data);
  }

  getBabyReportData(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanagerupdate/get', data);
  }


  createRecentInvestigation(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/recentInvestigation/create', data);
  }

  getRecentInvestigation(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/recentInvestigation/get', data);
  }

  createImaging(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/imaging/create', data);
  }

  getImaging(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/imaging/get', data);
  }

  getRespiratory(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/tr_support/get', data);
  }

  createRSNurseCompliences(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/nurse_compliance/create', data);
  }

  gettTr_phototherapy(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/tr_phototherapy/get', data);
  }

  gettTr_investigation(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/tr_investigation/get', data);
  }

  gettTr_Nurse_Advisory(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/tr_investigation/get', data);
  }

  //create medication  complience
  createMDNurseCompliences(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/tr_medication/addcompliance', data);
  }

  //create feed  complience
  createFENurseCompliences(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/feed_medication/addcompliance', data);
  }

  //create IV Fluid  complience
  createIVFluidNurseCompliences(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/ivfluid_medication/addcompliance', data);
  }
  //create IV TPN Fluid  complience
  createIVFluidTPNNurseCompliences(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/tpn_feed/addcompliance', data);
  }

  //create additives complience
  createAdditivesNurseCompliences(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/additives_medication/addcompliance', data);
  }

  //create fluid balance
  createFluidBalance(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/fluidbalancechart/create', data);
  }
  //getfluid balance
  getFluidBalance(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/fluidbalancechart/get', data);
  }


  //Update baby
  updateBaby(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babywarmer/update', data);
  }

  //Update baby
  AdmitBaby(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanager/admitbabyv2', data);
  }


  dischargeBaby(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanager/dischargev2', data);
  }

  getBabyByIDMRN(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanager/filterbymrn', data);
  }

  getClinicalAssismentReport(req): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/report/clinical`, req);
  }

  //get Ack record 
  getACKClinicalAssismentForm(req): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/clinicalassement/getrecordstatus`, req);
  }

  //  configure apis

  getMachinesForConfiguration(req: {
    "siteid": number
  }): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/machine/1.1/allocatedmachinesbysite`, req);
  }
  getHallsForConfiguration(req: {
    "siteid": number
  }): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/site/1.1/getavailablehalls`, req);
  }
  assignHallsAndBeds(req: {
    "machineid": number,
    "siteid": number,
    "hallid": number,
    "bedid": number,
    "assignedby": number
  }): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/site/1.1/assignhallbed`, req);
  }

  getHallInfo(req: { "usermasterid": number }): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/usermaster/crm/gethallinfo`, req);

  }


  //  alertlogs apis 

  getAnalyticsForVitals(req: {
    "babyid": Number,
    "startdate": string,
    "page": Number,
    "pagesize": Number
  }) {
    return this.http.post(`${this.baseUrl}v1/alarmlog/analytics`, req);
  }

  getVitalAlerts(req: {
    "babyid": Number,
    "startdate": string,
    "page": Number,
    "pagesize": Number
  }) {
    return this.http.post(`${this.baseUrl}v1/alarmlog/alertlogs`, req);
  }

  getCloudAlerts(req: {
    "babyid": Number,
    "startdate": string,
    "page": Number,
    "pagesize": Number
  }) {
    return this.http.post(`${this.baseUrl}v1/alarmlog/getcloudalerts`, req);
  }


  getClientDetailsforReport(req): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/usermaster/getClient`, req);
  }


  createDischargeDraftReport(req): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/babymanagerupdate/dischargedraft/create`, req);
  }

  updateDischargeDraftReport(req): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/babymanagerupdate/dischargedraft/update`, req);
  }


  deleteDischargeDraftReport(req): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/babymanagerupdate/dischargedraft/delete`, req);
  }

  deleteDraftNCIUJourneyReport(req): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/babymanagerupdate/dischargedraft/nicujourney/delete`, req);
  }


  getAllHalls(req: {
    "clientid": Number,
    "siteid": Number
  }) {
    return this.http.post(`${this.baseUrl}v1/hall/get`, req);
  }

  getDraftReportsLogs(req): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/babymanagerupdate/dischargedraft/get`, req);
  }

  getDraftReportbyID(req): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/babymanagerupdate/dischargedraft/fetchalldata`, req);
  }

  fetchAdmissionFieldMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanagerupdate/admissionfieldmaster/fetch', data);
  }

  createAdmissionMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanagerupdate/admissionmaster/create', data);
  }

  updateAdmissionMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanagerupdate/admissionmaster/update', data);
  }

  fetchAdmissionMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanagerupdate/admissionmaster/fetch', data);
  }
  deleteAdmissionMaster(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanagerupdate/admissionmaster/delete', data);
  }

  // admission notes crud api endpoints 

  createAdmissionNotes(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanagerupdate/admissionnotes/create', data);
  }

  updateAdmissionNotes(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanagerupdate/admissionnotes/update', data);
  }

  fetchAdmissionNotes(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanagerupdate/admissionnotes/fetch', data);
  }
  deleteAdmissionNotes(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanagerupdate/admissionnotes/delete', data);
  }

  fetchAdmissionAutoCompleteList(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanagerupdate/admissionnotes/fetch/prnmother', data);
  }

  fetchAdmissionNoteFilterList(data): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/babymanagerupdate/admissionnotes/fetch/filter', data);
  }

  fetchICUData(req): Observable<any> {
    return this.http.post(`${this.baseUrl}v1/dashboard/vitalsignofemac`, req);
  }





}
