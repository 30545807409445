<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- User Profile-->


        <!-- First level menu -->
        <li class="sidebar-item bgset" [class.active]="showMenu === sidebarnavItem.title"
            *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true"><i
                    [ngClass]="[sidebarnavItem.icon]"></i><span class="hide-menu">{{sidebarnavItem.title}}</span></div>
            <a class="sidebar-link waves-effect waves-dark"
                [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}</span>
            </a>

            <!-- Second level menu -->
            <ul aria-expanded="false" class="collapse first-level" *ngIf="sidebarnavItem.submenu.length > 0"
                [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li class="sidebar-item bgset2" *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
                    [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                    <a class="sidebar-link " style="margin-left: 5px;"
                        [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]"
                        [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink;"
                        (click)="addActiveClass(sidebarnavSubItem.title)">
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        <span class="hide-menu">{{sidebarnavSubItem.title}}</span>
                    </a>
                    <!-- Third level menu -->
                    <ul aria-expanded="false" class="collapse Second-level" *ngIf="sidebarnavSubItem.submenu.length > 0"
                        [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu"
                            routerLinkActive="active" [ngClass]="[sidebarnavSubsubItem.class]">
                            <a class="sidebar-link" [routerLink]="[sidebarnavSubsubItem.path]"
                                *ngIf="!sidebarnavSubsubItem.extralink;"
                                [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'">
                                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                                <span class="hide-menu">{{sidebarnavSubsubItem.title}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <!-- <li class="nav-item w-100 ">
            <div class="d-flex justify-content-center flex-direction-row flex-wrap">
                <div><img
                        src="https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"
                        alt="user" class="img-circle rounded-circle" width="50" style="width:35px"></div>


            </div>

            <p class="text-center" style="color: white;">{{ userData.usercategoryname}}</p>

            <div class="d-flex flex-direction-row align-items-center justify-content-center w-100">
                <button type="button" class="btn btn-outline-secondary rounded" routerLink="/birdmeditechprofile">My
                    Profile</button>
                <button type="button" class="btn btn-outline-secondary rounded" routerLink="logout()">Logout </button>
            </div>

        </li> -->

    </ul>
</nav>

<!--#384f64-->
<style>
    .bgset:hover {
        background-color: #708fa8;
        border-left: 5px solid rgb(255, 255, 255);
        margin: 2.2px;

    }

    .bgset2:hover {
        background-color: #314150;
        margin: 2.2px;
        border-right: 8.4px solid rgb(4, 211, 4);
        border-radius: 10px;
    }
</style>