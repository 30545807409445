import { Component, AfterViewInit, EventEmitter, Output, OnInit } from '@angular/core';
import {formatDate } from '@angular/common';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Time } from '@angular/common';
import { TimeInterval } from 'rxjs/internal/operators/timeInterval';
import { Observable } from 'rxjs';
//import { number } from '@amcharts/amcharts4/core';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit,OnInit {
  //public today= new Date();
  todaysDataTime = '';
  todaysDate = '';
  todaysTime = '';
  count:any =1;
  today:number;
  time:any;

  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  userData:any;
  //public now: Date = new Date();

  constructor(private modalService: NgbModal) {
   /* this.today = Date.now();
    this.time = new Observable<string>(observer => {
      setInterval(()=> observer.next(new Date().toLocaleTimeString()),1000)
    });

    console.log(this.time);
    */

   this.userData = JSON.parse(sessionStorage.getItem('userInfo1'));
  }


  ngOnInit() {
    /*setInterval(function(){
      this.today = new Date();
      this.todaysDate = formatDate(this.today, 'dd-MM-yyyy ', 'en-US')
      this.todaysTime = formatDate(this.today,'hh:mm:ss', 'en-US','+0530')
      this.count = this.count + 1;
      console.log(this.todaysDate,this.todaysTime)
     }, 1000);*/
  }

  // This is for Notifications
  notifications: Object[] = [
    {
      btn: 'btn-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      btn: 'btn-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      btn: 'btn-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      btn: 'btn-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  ngAfterViewInit() {}
}

function logout() {
  // remove user from local storage to log user out
  console.log("User Master Dashboard Logout()");
  sessionStorage.setItem('flag','true');
  localStorage.removeItem('username');
  sessionStorage.removeItem('userInfo');
}

