<div class="container mt-5">
    <div class="header">
        <div class="row">
            <div class="col-sm-8">
                <p style="font-size: 30px;color: white;text-align: left;">John, Smith-Demo</p>
            </div>
            <div class="col-md-4">
                <span class="text-muted">Data will be updated every 30 seconds</span>&nbsp;&nbsp;
                <i class="fas fa-sync fa-spin me-2" style="color: white;"></i>
            </div>
        </div>
        <!-- <h2>ECG V2 Lead Off</h2>   -->
    </div>
    <div class="metrics row">
        <div class="metric col-sm-4">
            <p style="color: lime">ECG(BPM)</p>
            <p style="color: lime;font-size: 70px;margin-top: 2px;text-align: right;">{{ vitalSignDashboard.MDC_ECG_HEART_RATE }}</p>
            <!-- <p>bpm</p> -->
        </div>
        <div class="metric spo2 col-sm-4">
            <p style="color: rgb(32, 235, 237);">SpO2(%)</p>
            <p style=" color: rgb(32, 235, 237);font-size: 70px;margin-top: 2px;text-align: right;">{{ vitalSignDashboard.MDC_PULS_OXIM_SAT_O2 }}</p>
        </div>
        <div class="metric resp col-sm-4">
            <p style="color: rgb(255, 226, 6)">Resp(RPM)</p>
            <p style="color: rgb(255, 226, 6); font-size: 70px;margin-top: 2px;text-align: right;">{{ vitalSignDashboard.MDC_PULS_OXIM_PLETH_RESP_RATE }}</p>
        </div>
        <div class="metric nibp col-sm-8 mt-5 text-center">
            <p style="color: rgb(83, 83, 159)">NIBP(mmHg)</p>
            <p style="color: rgb(83, 83, 159); font-size: 70px;margin-top: 2px;text-align: right;">{{vitalSignDashboard.nibp_sys}}/{{vitalSignDashboard.nibp_dia}}</p>
        </div>

        <div class="metric nibp col-sm-4 mt-5">
            <p style="color: rgb(83, 83, 159)">PR</p>
            <p style=" color: rgb(83, 83, 159); font-size: 70px;margin-top: 2px;text-align: right;">{{vitalSignDashboard.prvalue}}</p>
            
        </div>
    </div>

    <div class="footer mt-3">
        <p class="text-muted">Last Update Date & Time : {{ vitalSignDashboard.LASTUPDATEDATETIME }}</p>
        <p class="text-muted">Source: Mindray eMac 120 </p>
    </div>
</div>