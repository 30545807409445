<!-- style="background-image: linear-gradient(180deg,skyblue,rosybrown);box-shadow: 10px 10px 5px #aaaaaa;" -->
<div class="auth-wrapper"  style="background-image: url('assets/images/person1.jpg');background-repeat: no-repeat;background-size: 100%;">
    <div class="row">
        <div class="col-sm-4">
          <div class="auth-box border_radius cardmainstyle">
            <div class="logo mb-3">
              <span class="db1"><img src="assets/images/ok2.png" height="100px" width="200px" alt="logo" style="background:100% ;"/></span>
              <!-- <h3 class="font-medium m-b-20 m-3">Smart Baby Warmer</h3> -->

          </div>
          <div *ngIf="msg" class="alert alert-danger ">{{ msg }}</div>

            <form [formGroup]="registrationForm" class="mx-auto" (ngSubmit) = "loginSubmit()">
              <div class="form-group">
                <label for="username" class="text-white">Username:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="username"
                  formControlName="username"
                />

              </div>
              <div class="form-group">
                <label for="password" class="text-white">Password:</label>
                <div class="input-group">

                  <input
                    [type]="fieldTextType ? 'text' : 'password'"
                    class="form-control"
                    formControlName="password"
                    placeholder="password"
                  />
                    <div class="input-group-append">
                    <span class="input-group-text">
                      <i
                          class="fa"
                          [ngClass]="{
                            'fa-eye-slash': !fieldTextType,
                            'fa-eye': fieldTextType
                          }"
                          (click)="toggleFieldTextType()"
                        ></i>
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                    <a routerLink="/forgot-password"  id="to-recover" class="text-white float-right seta"><i
                        class="fa fa-lock"></i> Forgot password?</a>
                </div>
            </div>

              <button type="submit"
                class="btn-success btn btn-block"
                [disabled]="!registrationForm.valid"
              >
                Login
              </button>
            </form>
          </div>
        </div>
        <div class="col-sm-6">

        </div>
    </div>

</div>



<!---

<div>
                <div class="auth-box border_radius">
                    <div id="loginform" [ngClass]="{'d-none': recoverform}">
                        <div class="logo">
                            <span class="db"><img src="assets/images/logo-icon.png" alt="logo" /></span>
                            <h5 class="font-medium m-b-20 m-3">Sign In to Admin</h5>

                        </div>
                        <div *ngIf="msg" class="alert alert-danger">{{ msg }}</div>

                        <div class="row">
                            <div class="col-12">
                                <form class="form-horizontal m-t-20" id="loginform">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                                        </div>
                                        <input type="text" class="form-control border-secondary no-gutters border-right-0 rounded-0 border_radius" placeholder="Username" aria-label="Username"
                                        aria-describedby="basic-addon1" #username>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>
                                        </div>
                                        <input type="password" class="form-control border-secondary no-gutters border-right-0 rounded-0" placeholder="Password" aria-label="Password"
                                        aria-describedby="basic-addon1" #password>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <a (click)="showRecoverForm()" href="javascript:void(0)" id="to-recover" class="text-white float-right seta"><i
                                                class="fa fa-lock"></i> Forgot pwd?</a>
                                        </div>
                                    </div>
                                    <div class="form-group text-center">
                                        <div class="col-xs-12 p-b-20">
                                            <button class="btn btn-block btn-info border_radius" type="button" (click)="check(username.value,password.value)">Log
                                            In</button>
                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                    <div id="recoverform" [ngClass]="{'d-block': recoverform}">
                        <div class="logo">
                            <span class="db"><img src="assets/images/logo-icon.png" alt="logo" /></span>
                            <h5 class="font-medium m-b-20">Recover Password</h5>
                            <span>Enter your Email and instructions will be sent to you!</span>
                        </div>
                        <div class="row m-t-20">

                            <form class="col-12">

                                <div class="form-group row">
                                    <div class="col-12">
                                        <input class="form-control form-control-lg" type="email" required="" placeholder="Username">
                                    </div>
                                </div>

                                <div class="row m-t-20">
                                    <div class="col-12">
                                        <button class="btn btn-block btn-lg btn-danger" type="submit" name="action">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


-->
