import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { NgxSpinnerService } from "ngx-spinner";
import { MainserviceService } from '../mainservice.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { ActivatedRoute } from '@angular/router';

// Themes begin
am4core.useTheme(am4themes_animated);


let chart, dateAxis, series, interfaceColors;
let valueAxis;

@Component({
  selector: 'app-trends',
  templateUrl: './trends.component.html',
  styleUrls: ['./trends.component.css'],
  providers: [DatePipe]
})

export class TrendsComponent implements OnInit {

  fileName = 'ExcelSheet.xlsx';

  trendsform: trends_form = new trends_form();
  selectedValue = 'default';
  msg: any;
  temp_stat = [];
  air_temp = [];
  pheri_temp = [];
  set_temp = [];
  spo2_trend = [];
  pulserate_trend = [];
  deltat_trend = [];
  weight_trend = [];

  temp_show = false;
  spo2_show = false;
  weight_show = false;
  weight_tab_show1 = false;
  apgar_tread_show = false;
  weight_trends = false;
  delta_treade_show = false;

  displayflagmsg = false;
  displayflag = false;
  machineList: any;
  select_machine: Number;

  isFullScreenEnabled:boolean = false;

  // machines = ['Machine-12',"Machine-13","Machine-14"];
  perameternames = ["temperature", "spo2 and pulse rate", "deltat", "Weight", "HR", "PI", "RR", "NBP(Sys/Dia)", "All"];
  durations = ["This Month", "This Week", "Yesterday", "Todays"];
  filter_msg: any
  filter_msg_flag: boolean = false;
  chartData: any = [];
  tempdata: any;
  weightData: any[];
  heterData: any[];
  heter_show: boolean;
  chartData1: any;
  PI_show: any;
  HR_show: boolean;
  RR_show: any;
  NBP_show: boolean;
  pi_trend: any[];
  chartData2: any[];
  chartData3: any[];
  chartData4: any;
  chartData5: any;
  tempStatsVisibility: boolean = false;
  deltatStatsVisibility: boolean = false;
  weightStatsVisibility: boolean = false;
  deltaTData: any = [];
  /*
  response_data[i].settemperature
  */

  noDisplay = false;
  constructor(private spinner: NgxSpinnerService, private mainserviceService: MainserviceService, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
    //this.getspotwopulserate();

    this.getmachine();

    // this.heterGraph();

    this.trendsform.perametername = "temperature";
    this.trendsform.duration = "Todays"


  }

  exportexcel(): void {


    //get paramter details 
    if (this.trendsform.perametername == "temperature") {
      const workBook = XLSX.utils.book_new(); // create a new blank book
      const workSheet = XLSX.utils.json_to_sheet(this.tempdata);

      XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
      XLSX.writeFile(workBook, 'Temp' + new Date() + '' + '.xlsx'); // initiate a file download in browser
    } else if (this.trendsform.perametername == "Weight") {
      const workBook = XLSX.utils.book_new(); // create a new blank book
      const workSheet = XLSX.utils.json_to_sheet(this.weightData);

      XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
      XLSX.writeFile(workBook, 'Weight' + new Date() + '' + '.xlsx'); // initiate a file download in browser
    }


  }

  ngOnInit(): void {
    // this.activatedRoute.snapshot.params.get('id');
    this.trendsform.perametername = 'All';
    this.perameterchange();
  }


  machinechange() {
    this.trends_submit();
  }

  perameterchange() {
    this.trends_submit();
  }

  chunkTemperatureData(dataArray, callback) {
    let chunkArray = dataArray.splice(0, 100);
    if (chunkArray.length > 0) {
      chunkArray.forEach(ele => {
        this.chartData.push({
          date: new Date(ele.lastupdatetime),
          setTemp: ele.settemperature,
          babyTemp: ele.babytemperature,
          airTemp: ele.airtemperature,
          periTemp: ele.pheripheraltemperature,
          heaterPower: ele.heaterpower,
          mvalue: ele.mvalue
        })
      })
      this.chunkTemperatureData(dataArray, callback);
    } else {
      callback();
    }
  }

  chunkspoandpulserateData(dataArray, callback) {
    let chunkArray = dataArray.splice(0, 400);
    if (chunkArray.length > 0) {
      chunkArray.forEach(ele => {
        this.chartData1.push({
          date: new Date(ele.lastupdatetime),
          spo2: ele.spotwovalue,
          pulserate: ele.pulseratevalue,
          pivalue: ele.pivalue,
          HRvalue: ele.HRvalue,
          Weightvalue: ele.Weightvalue,
          RRvalue: ele.RRvalue,
          sysvalue: ele.sysvalue,
          diavalue: ele.diavalue

        })

      })
      this.chunkspoandpulserateData(dataArray, callback);
    } else {
      callback();
    }
  }

  chunkPIData(dataArray, callback) {
    let chunkArray = dataArray.splice(0, 400);
    if (chunkArray.length > 0) {
      chunkArray.forEach(ele => {
        this.chartData2.push({
          date: new Date(ele.lastupdatetime),

          pivalue: ele.pivalue,


        })

      })
      this.chunkPIData(dataArray, callback);
    } else {
      callback();
    }
  }

  chunkHRData(dataArray, callback) {
    let chunkArray = dataArray.splice(0, 400);
    if (chunkArray.length > 0) {
      chunkArray.forEach(ele => {
        this.chartData3.push({
          date: new Date(ele.lastupdatetime),
          HRvalue: ele.HRvalue,
        })
      })
      this.chunkHRData(dataArray, callback);
    } else {
      callback();
    }
  }


  chunkNBPData(dataArray, callback) {
    let chunkArray = dataArray.splice(0, 400);
    if (chunkArray.length > 0) {
      chunkArray.forEach(ele => {
        this.chartData4.push({
          date: new Date(ele.lastupdatetime),
          sysvalue: ele.sysvalue,
          diavalue: ele.diavalue
        })
      })
      this.chunkNBPData(dataArray, callback);
    } else {
      callback();
    }
  }

  chunkRRData(dataArray, callback) {
    let chunkArray = dataArray.splice(0, 400);
    if (chunkArray.length > 0) {
      chunkArray.forEach(ele => {
        this.chartData5.push({
          date: new Date(ele.lastupdatetime),
          RRvalue: ele.RRvalue,
        })

      })
      this.chunkRRData(dataArray, callback);
    } else {
      callback();
    }
  }

  temperatureGraph(response_data) {

    const chart = am4core.create('tempchart', am4charts.XYChart);

    // Increase contrast by taking evey second color
    chart.colors.step = 2;
    am4core.options.autoDispose = true;
    this.chartData = [];
    // Add data
    this.chunkTemperatureData(response_data, () => {
      chart.data = this.chartData;
    });

    this.statchunk(chart.data);
    this.tempdata = chart.data;

    // chart.data = generateChartData();
    // let axisData = [20,22,24,26,28,30,32,34,36,38,40,44];
    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.title.text = "Date & Time";
    dateAxis.renderer.minGridDistance = 60;



    // dateAxis.renderer.labels.template.fill = am4core.color('white');
    // Create series
    const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis1.title.text = "Temperature(°C)";
    // valueAxis1.min = 1;
    // valueAxis1.max = 100;
    valueAxis1.title.text = "Temperature(°C)";
    const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis2.title.text = "Heater power(%)";
    // valueAxis2.min = 1;
    // valueAxis2.max = 100;
    function createAxisAndSeries(field, name, opposite, bullet, t) {
      let valueAxis;
      let color = '';
      valueAxis = valueAxis2;
      color = 'red';
      // if (t === 1) {
      //   valueAxis = valueAxis1;
      //   color = 'blue';
      // }
      //  else {
      //   valueAxis = valueAxis2;
      //   color = 'red';
      // }

      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();

      chart.scrollbarY.parent = chart.leftAxesContainer;
      chart.scrollbarY.toBack();

      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'date';
      series.strokeWidth = 1;
      series.yAxis = valueAxis;
      series.name = name;
      series.tooltipText = '{name}: [bold]{valueY}[/]';
      series.tensionX = 0.8;
      series.showOnInit = true;
      // series.stroke = am4core.color(color);
      // set stroke property field
      series.propertyFields.stroke = 'color';

      const interfaceColors = new am4core.InterfaceColorSet();

      if (t === 1) {
        switch (bullet) {
          case 'triangle':
            const bullet1 = series.bullets.push(new am4charts.Bullet());
            bullet1.width = 10;
            bullet1.height = 10;
            bullet1.horizontalCenter = 'middle';
            bullet1.verticalCenter = 'middle';

            const triangle = bullet1.createChild(am4core.Triangle);
            triangle.stroke = interfaceColors.getFor('background');
            triangle.strokeWidth = 2;
            triangle.direction = 'top';
            triangle.width = 10;
            triangle.height = 10;
            break;
          case 'rectangle':
            const bullet2 = series.bullets.push(new am4charts.Bullet());
            bullet2.width = 10;
            bullet2.height = 10;
            bullet2.horizontalCenter = 'middle';
            bullet2.verticalCenter = 'middle';

            const rectangle = bullet2.createChild(am4core.Rectangle);
            rectangle.stroke = interfaceColors.getFor('background');
            rectangle.strokeWidth = 2;
            rectangle.width = 10;
            rectangle.height = 10;
            break;
          default:
            const bullet3 = series.bullets.push(new am4charts.CircleBullet());
            bullet3.circle.stroke = interfaceColors.getFor('background');
            bullet3.circle.strokeWidth = 2;
            break;
        }
      }

      valueAxis.renderer.line.strokeOpacity = 1;
      valueAxis.renderer.line.strokeWidth = 1;
      valueAxis.renderer.line.stroke = series.stroke;
      valueAxis.renderer.labels.template.fill = series.stroke;
      valueAxis.renderer.opposite = opposite;


    }

    createAxisAndSeries('setTemp', 'Set', false, 'circle', 2);
    createAxisAndSeries('babyTemp', 'Baby', false, 'triangle', 2);
    createAxisAndSeries('airTemp', 'Air', false, 'rectangle', 2);
    createAxisAndSeries('periTemp', 'Peripheral', false, 'rectangle', 2);
    createAxisAndSeries('mvalue', 'Mode', false, 'rectangle', 2);
    //createAxisAndSeries('heaterPower', 'Heater Power', true, 'rectangle', 2);



    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color('black');

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

  }

  generateHeterChartData() {
    const chartData = [];
    const firstDate = new Date();
    firstDate.setDate(firstDate.getDate() - 100);
    firstDate.setHours(0, 0, 0, 0);

    let heter;

    // let views = 8700;

    for (let i = 0; i < 15; i++) {
      // we create date objects here. In your data, you can have date strings
      // and then set format of your dates using chart.dataDateFormat property,
      // however when possible, use date objects, as this will speed up chart rendering.
      const newDate = new Date(firstDate);
      newDate.setDate(newDate.getDate() + i);

      heter = Math.round((Math.floor(Math.random() * (100 - 0)) + 0));


      chartData.push({
        date: newDate,
        heter
      });
    }
    return chartData;
  }

  //getspoandpulserate

  getspotwopulserate(machinename, fromdate, todate, duration) {

    let obj = {
      "machineid": Number(machinename),
      "fromdate": fromdate,
      "todate": todate
    }

    this.mainserviceService.getspoandpulserate(obj).subscribe((res) => {


      if (res.status_code == "s_407") {
        // this.spo2_show  = true;
        this.msg = '';

        this.spo2GraphFun(res && res.data && res.data.length > 0 ? res.data : []);
        this.spo2_trend = [];
        // this.spo2_trend.push(res.statdata[0].spostat);
        // this.pulserate_trend.push(res.statdata[0].prstat);
      } else if (res.status_code == "s_408") {
        //return res.data;

        this.msg = duration + ' Spo2 and Pulserate Data does not Found';
      } else if (res.status_code == "s_1015") {

        this.msg = 'A Error Occured, Please Contact System Administrator';
      } else {
        //return "Data Does Not Found";
        this.msg = 'Something Wrong';

      }
    }, (err) => {

    });

    this.mainserviceService.getspoandpulseratestate(obj).subscribe((res) => {


      if (res.status_code == "s_407") {
        // this.spo2_show  = true;

        this.spo2_trend = [];
        this.spo2_trend.push(res.data[0].spostat);
        this.pulserate_trend.push(res.data[0].prstat);
      } else if (res.status_code == "s_408") {
        //return res.data;

        this.msg = duration + ' Spo2 and Pulserate Data does not Found';
      } else if (res.status_code == "s_1015") {

        this.msg = 'A Error Occured, Please Contact System Administrator';
      } else {
        //return "Data Does Not Found";
        this.msg = 'Something Wrong';

      }
    }, (err) => {

    });


  }

  gettemperaturedata(machinename, fromdate, todate, duration) {
    let obj = {
      "machineid": Number(machinename),
      "fromdate": moment(new Date(fromdate)).format('YYYY-MM-DD'),
      "todate": moment(new Date(todate)).format('YYYY-MM-DD')
    }


    this.mainserviceService.gettempdata(obj).subscribe((res) => {


      if (res.status_code == "s_407") {
        this.msg = '';

        this.temperatureGraph(res && res.data && res.data.length > 0 ? res.data : [])

        // if (res.data.length > 0) {
        //   this.temp_stat = [];
        //   this.air_temp = [];
        //   this.pheri_temp = [];
        //   this.set_temp = [];

        //   this.temp_stat.push(res.statdata[0].babytempstat);
        //   this.air_temp.push(res.statdata[0].airtempstat);
        //   this.pheri_temp.push(res.statdata[0].pheritempstat);
        //   this.set_temp.push(res.statdata[0].settempstat);


        // }
      } else if (res.status_code == "s_408") {
        //return res.data;
        // this.displayflag = false;
        this.temp_show = false;

        this.msg = duration + ' Temperature Data does not Found';
      } else if (res.status_code == "s_1015") {
        this.msg = 'An Error Occured, Please Contact System Administrator';
      } else {
        //return "Data Does Not Found";
        this.msg = 'Something Wrong';

      }
    }, (err) => {

    });
  }

  statchunk(data) {
    let tempdata = data;
    this.temp_stat = [];
    this.air_temp = [];
    this.pheri_temp = [];
    this.set_temp = [];


    // baby temp stat

    let max = Math.max.apply(Math, tempdata.map(function (o) { return o.babyTemp }));
    let min = Math.min.apply(Math, tempdata.map(function (o) { return o.babyTemp }));
    let average = Number((tempdata.reduce((a, b) => a + b.babyTemp, 0) / tempdata.length).toFixed(2));
    let mid = Math.floor(tempdata.length / 2);
    let nums = [...tempdata].sort((a, b) => a.babyTemp - b.babyTemp);
    let median = (tempdata % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2).babyTemp;
    let mode = this.mode([...new Set(tempdata.map(x => x.babyTemp))]);
    let std = Number(this.standarddeviation([...new Set(tempdata.map(x => x.babyTemp))], average));
    let obj = { max: max, min: min, average: average, median: median, mode: mode, std: std }
    this.temp_stat.push(obj);


    //air temp stat

    let max1 = Math.max.apply(Math, tempdata.map(function (o) { return o.airTemp }));
    let min1 = Math.min.apply(Math, tempdata.map(function (o) { return o.airTemp }));
    let average1 = Number((tempdata.reduce((a, b) => a + b.airTemp, 0) / tempdata.length).toFixed(2));
    let mid1 = Math.floor(tempdata.length / 2);
    let nums1 = [...tempdata].sort((a, b) => a.airTemp - b.airTemp);
    let median1 = (tempdata % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2).airTemp;
    let mode1 = this.mode([...new Set(tempdata.map(x => x.airTemp))]);
    let std1 = Number(this.standarddeviation([...new Set(tempdata.map(x => x.airTemp))], average));
    let obj1 = { max: max1, min: min1, average: average1, median: median1, mode: mode1, std: std1 }
    this.air_temp.push(obj1);

    //pheri temp stat

    let max2 = Math.max.apply(Math, tempdata.map(function (o) { return o.periTemp }));
    let min2 = Math.min.apply(Math, tempdata.map(function (o) { return o.periTemp }));
    let average2 = Number((tempdata.reduce((a, b) => a + b.periTemp, 0) / tempdata.length).toFixed(2));
    let mid2 = Math.floor(tempdata.length / 2);
    let nums2 = [...tempdata].sort((a, b) => a.periTemp - b.periTemp);
    let median2 = (tempdata % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2).periTemp;
    let mode2 = this.mode([...new Set(tempdata.map(x => x.periTemp))]);
    let std2 = Number(this.standarddeviation([...new Set(tempdata.map(x => x.periTemp))], average));
    let obj2 = { max: max2, min: min2, average: average2, median: median2, mode: mode2, std: std2 }
    this.pheri_temp.push(obj2);


    //set temp

    let max3 = Math.max.apply(Math, tempdata.map(function (o) { return o.setTemp }));
    let min3 = Math.min.apply(Math, tempdata.map(function (o) { return o.setTemp }));
    let average3 = Number((tempdata.reduce((a, b) => a + b.setTemp, 0) / tempdata.length).toFixed(2));
    let mid3 = Math.floor(tempdata.length / 2);
    let nums3 = [...tempdata].sort((a, b) => a.setTemp - b.setTemp);
    let median3 = (tempdata % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2).setTemp;
    let mode3 = this.mode([...new Set(tempdata.map(x => x.setTemp))]);
    let std3 = Number(this.standarddeviation([...new Set(tempdata.map(x => x.setTemp))], average));
    let obj3 = { max: max3, min: min3, average: average3, median: median3, mode: mode3, std: std3 }
    this.set_temp.push(obj3);

  }

  statweight = (data) => {
    let tempdata = data
    // weight_trend

    let max = Math.max.apply(Math, tempdata.map(function (o) { return o.value }));
    let min = Math.min.apply(Math, tempdata.map(function (o) { return o.value }));
    let average = Number((tempdata.reduce((a, b) => a + b.value, 0) / tempdata.length).toFixed(2));
    let mid = Math.floor(tempdata.length / 2);
    let nums = [...tempdata].sort((a, b) => a.value - b.value);
    let median = (tempdata % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2).value;
    let mode = this.mode([...new Set(tempdata.map(x => x.value))]);
    let std = Number(this.standarddeviation([...new Set(tempdata.map(x => x.value))], average));
    let obj = { max: max, min: min, average: average, median: median, mode: mode, std: std }
    this.weight_trend.push(obj);

  }
  mode = (array) => {
    const map = new Map();
    let maxFreq = 0;
    let mode;

    for (const item of array) {
      let freq = map.has(item) ? map.get(item) : 0;
      freq++;

      if (freq > maxFreq) {
        maxFreq = freq;
        mode = item;
      }

      map.set(item, freq);
    }
    return mode;
  };


  standarddeviation = (array, mean) => {
    return Math.sqrt(array.reduce(function (sq, n) {
      return sq + Math.pow(n - mean, 2);
    }, 0) / (array.length - 1)).toFixed(2);
  };


  getdeltattrendsdata(machinename, fromdate, todate, duration) {

    let obj = {
      "machineid": Number(machinename),
      "fromdate": fromdate,
      "todate": todate
    }

    this.mainserviceService.getdeltatdata(obj).subscribe((res) => {
      this.deltaTData = res;

      if (res.status_code == "s_407") {
        this.msg = '';
        this.deltaTGraph(res && res.data && res.data.length > 0 ? res.data : []);
        //this.temperatureGraph(res && res.data && res.data.length > 0 ? res.data : [])
        if (res.data.length > 0) {
          this.deltat_trend = [];
          this.deltat_trend.push(res.statdata[0].deltatstat)
        }

      } else if (res.status_code == "s_408") {
        //return res.data;

        this.msg = duration + ' Delta-T Data does not Found';
      } else if (res.status_code == "s_1015") {

        this.msg = 'An Error Occured, Please Contact System Administrator';
      } else {
        //return "Data Does Not Found";
        this.msg = 'Something Wrong';

      }
    }, (err) => {

    });
  }

  getweighttrendsdata(machinename, fromdate, todate, duration) {

    let obj = {
      "machineid": Number(machinename),
      "fromdate": fromdate,
      "todate": todate
    }

    this.mainserviceService.getweightdata(obj).subscribe((res) => {


      if (res.status_code == "s_407") {
        this.msg = '';
        this.weightGraph(res && res.data && res.data.length > 0 ? res.data : []);
        if (res.data.length > 0) {
          this.weight_trend = [];
          this.weight_trend.push(res.statdata[0].weightstat)
        }

      } else if (res.status_code == "s_408") {
        this.temp_show = false;
        this.weight_show = false;
        this.msg = duration + ' Weight Data does not Found';
      } else if (res.status_code == "s_1015") {

        this.msg = 'An Error Occured, Please Contact System Administrator';
      } else {
        //return "Data Does Not Found";
        this.msg = 'Something Wrong';

      }
    }, (err) => {

    });
  }

  getapgartrendsdata(machinename, fromdate, todate, duration) {

    let obj = {
      "machineid": Number(machinename),
      "fromdate": fromdate,
      "todate": todate
    }

    this.mainserviceService.getapgardata(obj).subscribe((res) => {


      if (res.status_code == "s_407") {
        this.msg = '';
        //this.temperatureGraph(res && res.data && res.data.length > 0 ? res.data : [])

      } else if (res.status_code == "s_408") {
        //return res.data;

        this.msg = duration + ' Apgar Data does not Found';
      } else if (res.status_code == "s_1015") {

        this.msg = 'An Error Occured, Please Contact System Administrator';
      } else {
        //return "Data Does Not Found";
        this.msg = 'Something Wrong';

      }
    }, (err) => {

    });
  }

  getmachine() {
    var userData = JSON.parse(sessionStorage.getItem('userInfo1'));

    let dummy_data = {
      "clientid": userData.clientid
    };

    this.mainserviceService.getmachineclientwise(dummy_data).subscribe((res) => {
      this.machineList = res.data;
      // this.trendsform.machinename = res.data[0].machineid;
      let id = this.activatedRoute.snapshot.params['id'];
      this.trendsform.machinename = id ? id : res.data[0].machineid;
      this.trends_submit()

    }, (err) => {
    });
  }

  trends_submit() {
    this.displayflag = true;

    if (this.trendsform.perametername == "temperature" || this.trendsform.perametername == 'All') {
      this.temp_show = true;
      if (this.trendsform.perametername != 'All') {
        this.spo2_show = false;
        this.heter_show = false;
        this.weight_show = false;
        this.apgar_tread_show = false;
        this.delta_treade_show = false;
        this.PI_show = false;
        this.HR_show = false;
        this.RR_show = false;
        this.NBP_show = false;
      }

      setTimeout(() => {

        if ((this.trendsform.machinename == null || this.trendsform.machinename == undefined) && (this.trendsform.duration == null || this.trendsform.duration == undefined)) {
          this.filter_msg = "Please Select Proper Warmer Id or Period";
        } else {
          if (this.trendsform.duration == "Todays") {
            this.gettemperaturedata(this.trendsform.machinename, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "Yesterday") {
            this.gettemperaturedata(this.trendsform.machinename, moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Week") {
            this.gettemperaturedata(this.trendsform.machinename, moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Month") {
            this.gettemperaturedata(this.trendsform.machinename, moment(new Date()).subtract(30, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          }
        }

      }, 1000);
    }
    if (this.trendsform.perametername == "Heter" || this.trendsform.perametername == 'All') {
      this.displayflag = true;
      this.heter_show = true;
      if (this.trendsform.perametername != 'All') {
        this.temp_show = false;
        this.spo2_show = false;
        this.weight_show = false;
        this.apgar_tread_show = false;
        this.delta_treade_show = false;
        this.PI_show = false;
        this.HR_show = false;
        this.RR_show = false;
        this.NBP_show = false;
      }

      setTimeout(() => {
        //this.heterGraph();
      }, 1000)


    }
    if (this.trendsform.perametername == "spo2 and pulse rate" || this.trendsform.perametername == 'All') {
      this.displayflag = true;
      this.spo2_show = true;
      if (this.trendsform.perametername != 'All') {
        this.heter_show = false;
        this.temp_show = false;
        // this.spo2_show = true;
        this.weight_show = false;
        this.apgar_tread_show = false;
        this.delta_treade_show = false;
        this.PI_show = false;
        this.HR_show = false;
        this.RR_show = false;
        this.NBP_show = false;
      }

      setTimeout(() => {

        if ((this.trendsform.machinename == null || this.trendsform.machinename == undefined) && (this.trendsform.duration == null || this.trendsform.duration == undefined)) {
          this.filter_msg = "Please Select Proper Warmer Id or Period";
        } else {
          if (this.trendsform.duration == "Todays") {
            this.getspotwopulserate(this.trendsform.machinename, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)

          } else if (this.trendsform.duration == "Yesterday") {
            this.getspotwopulserate(this.trendsform.machinename, moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Week") {
            this.getspotwopulserate(this.trendsform.machinename, moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Month") {
            this.getspotwopulserate(this.trendsform.machinename, moment(new Date()).subtract(30, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          }
        }
        //this.tempgraph
        //this.spo2GraphFun();
      }, 1000);
    }
    if (this.trendsform.perametername == "deltat" || this.trendsform.perametername == 'All') {
      this.displayflag = true;
      this.delta_treade_show = true;
      if (this.trendsform.perametername != 'All') {
        this.temp_show = false;
        this.heter_show = false;
        this.spo2_show = false;
        this.weight_show = false;
        this.apgar_tread_show = false;
        this.PI_show = false;
        this.HR_show = false;
        this.RR_show = false;
        this.NBP_show = false;
      }

      setTimeout(() => {
        // this.deltaTGraph();
        if ((this.trendsform.machinename == null || this.trendsform.machinename == undefined) && (this.trendsform.duration == null || this.trendsform.duration == undefined)) {
          this.filter_msg = "Please Select Proper Warmer Id or Period";
        } else {
          if (this.trendsform.duration == "Todays") {
            this.getdeltattrendsdata(this.trendsform.machinename, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "Yesterday") {
            this.getdeltattrendsdata(this.trendsform.machinename, moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Week") {
            this.getdeltattrendsdata(this.trendsform.machinename, moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Month") {
            this.getdeltattrendsdata(this.trendsform.machinename, moment(new Date()).subtract(30, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          }
        }
      }, 1000);


    }
    if (this.trendsform.perametername == "Weight" || this.trendsform.perametername == 'All') {
      this.displayflag = true;
      this.weight_show = true;
      if (this.trendsform.perametername != 'All') {
        this.temp_show = false;
        this.heter_show = false;
        this.spo2_show = false;
        this.apgar_tread_show = false;
        this.delta_treade_show = false;
        this.PI_show = false;
        this.HR_show = false;
        this.RR_show = false;
        this.NBP_show = false;
      }

      setTimeout(() => {
        // this.weightGraph();
        if ((this.trendsform.machinename == null || this.trendsform.machinename == undefined) && (this.trendsform.duration == null || this.trendsform.duration == undefined)) {
          this.filter_msg = "Please Select Proper Warmer Id or Period";
        } else {
          if (this.trendsform.duration == "Todays") {
            this.getweighttrendsdata(this.trendsform.machinename, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "Yesterday") {
            this.getweighttrendsdata(this.trendsform.machinename, moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Week") {
            this.getweighttrendsdata(this.trendsform.machinename, moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Month") {
            this.getweighttrendsdata(this.trendsform.machinename, moment(new Date()).subtract(30, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          }
        }

      }, 1000);


    }
    if (this.trendsform.perametername == "PI" || this.trendsform.perametername == 'All') {
      this.displayflag = true;
      this.PI_show = true;
      if (this.trendsform.perametername != 'All') {
        this.temp_show = false;
        this.spo2_show = false;
        this.heter_show = false;
        this.weight_show = false;
        this.apgar_tread_show = false;
        this.delta_treade_show = false;
        this.HR_show = false;
        this.RR_show = false;
        this.NBP_show = false;
      }

      setTimeout(() => {

        this.msg = '';

        if ((this.trendsform.machinename == null || this.trendsform.machinename == undefined) && (this.trendsform.duration == null || this.trendsform.duration == undefined)) {
          this.filter_msg = "Please Select Proper Warmer Id or Period";
        } else {
          if (this.trendsform.duration == "Todays") {
            this.getPI(this.trendsform.machinename, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "Yesterday") {
            this.getPI(this.trendsform.machinename, moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Week") {
            this.getPI(this.trendsform.machinename, moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Month") {
            this.getPI(this.trendsform.machinename, moment(new Date()).subtract(30, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          }
        }

      }, 1000);


    }
    if (this.trendsform.perametername == "HR" || this.trendsform.perametername == 'All') {
      this.displayflag = true;
      this.HR_show = true;
      if (this.trendsform.perametername != 'All') {
        this.temp_show = false;
        this.spo2_show = false;
        this.heter_show = false;
        this.weight_show = false;
        this.apgar_tread_show = false;
        this.delta_treade_show = false;
        this.PI_show = false;
        this.RR_show = false;
        this.NBP_show = false;
      }

      setTimeout(() => {

        this.msg = '';

        if ((this.trendsform.machinename == null || this.trendsform.machinename == undefined) && (this.trendsform.duration == null || this.trendsform.duration == undefined)) {
          this.filter_msg = "Please Select Proper Warmer Id or Period";
        } else {
          if (this.trendsform.duration == "Todays") {
            this.getHR(this.trendsform.machinename, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "Yesterday") {
            this.getHR(this.trendsform.machinename, moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Week") {
            this.getHR(this.trendsform.machinename, moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Month") {
            this.getHR(this.trendsform.machinename, moment(new Date()).subtract(30, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          }
        }

      }, 1000);


    }
    if (this.trendsform.perametername == "NBP(Sys/Dia)" || this.trendsform.perametername == 'All') {
      this.displayflag = true;
      this.NBP_show = true;
      if (this.trendsform.perametername != 'All') {
        this.temp_show = false;
        this.spo2_show = false;
        this.heter_show = false;
        this.weight_show = false;
        this.apgar_tread_show = false;
        this.delta_treade_show = false;
        this.PI_show = false;
        this.HR_show = false;
        this.RR_show = false;
      }

      setTimeout(() => {

        this.msg = '';

        if ((this.trendsform.machinename == null || this.trendsform.machinename == undefined) && (this.trendsform.duration == null || this.trendsform.duration == undefined)) {
          this.filter_msg = "Please Select Proper Warmer Id or Period";
        } else {
          if (this.trendsform.duration == "Todays") {
            this.getNBP(this.trendsform.machinename, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "Yesterday") {
            this.getNBP(this.trendsform.machinename, moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Week") {
            this.getNBP(this.trendsform.machinename, moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Month") {
            this.getNBP(this.trendsform.machinename, moment(new Date()).subtract(30, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          }
        }

      }, 1000);


    }
    if (this.trendsform.perametername == "RR" || this.trendsform.perametername == 'All') {
      this.displayflag = true;
      this.RR_show = true;
      if (this.trendsform.perametername != 'All') {
        this.temp_show = false;
        this.spo2_show = false;
        this.heter_show = false;
        this.weight_show = false;
        this.apgar_tread_show = false;
        this.delta_treade_show = false;
        this.PI_show = false;
        this.HR_show = false;
        this.NBP_show = false;
      }

      setTimeout(() => {

        this.msg = '';

        if ((this.trendsform.machinename == null || this.trendsform.machinename == undefined) && (this.trendsform.duration == null || this.trendsform.duration == undefined)) {
          this.filter_msg = "Please Select Proper Warmer Id or Period";
        } else {
          if (this.trendsform.duration == "Todays") {
            this.getRR(this.trendsform.machinename, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "Yesterday") {
            this.getRR(this.trendsform.machinename, moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Week") {
            this.getRR(this.trendsform.machinename, moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          } else if (this.trendsform.duration == "This Month") {
            this.getRR(this.trendsform.machinename, moment(new Date()).subtract(30, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), this.trendsform.duration)
          }
        }

      }, 1000);


    }

  }


  getPI(machinename, fromdate, todate, duration) {

    let obj = {
      "machineid": Number(machinename),
      "fromdate": fromdate,
      "todate": todate
    }

    this.mainserviceService.getspoandpulserate(obj).subscribe((res) => {


      if (res.status_code == "s_407") {
        // this.spo2_show  = true;
        this.msg = '';

        this.PIGraphFun(res && res.data && res.data.length > 0 ? res.data : []);
        this.pi_trend = [];

      } else if (res.status_code == "s_408") {
        this.msg = duration + 'PI Data does not Found';
      } else if (res.status_code == "s_1015") {
        this.msg = 'A Error Occured, Please Contact System Administrator';
      } else {
        this.msg = 'Something Wrong';
      }
    }, (err) => {

    });


  }

  getHR(machinename, fromdate, todate, duration) {

    let obj = {
      "machineid": Number(machinename),
      "fromdate": fromdate,
      "todate": todate
    }

    this.mainserviceService.getspoandpulserate(obj).subscribe((res) => {


      if (res.status_code == "s_407") {
        // this.spo2_show  = true;
        this.msg = '';

        this.HRGraphFun(res && res.data && res.data.length > 0 ? res.data : []);
        this.pi_trend = [];

      } else if (res.status_code == "s_408") {
        this.msg = duration + 'HR Data does not Found';
      } else if (res.status_code == "s_1015") {
        this.msg = 'A Error Occured, Please Contact System Administrator';
      } else {
        this.msg = 'Something Wrong';
      }
    }, (err) => {

    });


  }


  getNBP(machinename, fromdate, todate, duration) {

    let obj = {
      "machineid": Number(machinename),
      "fromdate": fromdate,
      "todate": todate
    }

    this.mainserviceService.getspoandpulserate(obj).subscribe((res) => {


      if (res.status_code == "s_407") {
        // this.spo2_show  = true;
        this.msg = '';

        this.NBPGraphFun(res && res.data && res.data.length > 0 ? res.data : []);
        this.pi_trend = [];

      } else if (res.status_code == "s_408") {
        this.msg = duration + 'NBP Data does not Found';
      } else if (res.status_code == "s_1015") {
        this.msg = 'A Error Occured, Please Contact System Administrator';
      } else {
        this.msg = 'Something Wrong';
      }
    }, (err) => {

    });


  }

  getRR(machinename, fromdate, todate, duration) {

    let obj = {
      "machineid": Number(machinename),
      "fromdate": fromdate,
      "todate": todate
    }

    this.mainserviceService.getspoandpulserate(obj).subscribe((res) => {


      if (res.status_code == "s_407") {
        // this.spo2_show  = true;
        this.msg = '';

        this.RRGraphFun(res && res.data && res.data.length > 0 ? res.data : []);
        this.pi_trend = [];

      } else if (res.status_code == "s_408") {
        this.msg = duration + 'RR Data does not Found';
      } else if (res.status_code == "s_1015") {
        this.msg = 'A Error Occured, Please Contact System Administrator';
      } else {
        this.msg = 'Something Wrong';
      }
    }, (err) => {

    });


  }

  spo2GraphFun(response_data) {
    const chart = am4core.create('spo2chart', am4charts.XYChart);
    // Increase contrast by taking evey second color
    chart.colors.step = 2;
    this.chartData1 = [];
    // Add data
    this.chunkspoandpulserateData(response_data, () => {
      chart.data = this.chartData1;
    });
    // chart.data = generateChartData();
    // let axisData = [20,22,24,26,28,30,32,34,36,38,40,44];
    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.title.text = "Date & Time";
    dateAxis.renderer.minGridDistance = 60;

    dateAxis.renderer.labels.template.fill = am4core.color('black');
    //Create series
    const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.title.text = "Vital Parameter(%)"
    valueAxis1.min = 1;
    valueAxis1.max = 200;
    // const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis2.title.text = "Vital Parameter(%)"
    // valueAxis2.min = 1;
    // valueAxis2.max = 200;
    function createAxisAndSeries(field, name, opposite, bullet, t) {
      let valueAxis;
      let color = '';


      // if (t === 1) {

      // } else {
      //   valueAxis = valueAxis2;
      //   color = 'cornflowerblue';
      // }

      valueAxis = valueAxis1;
      color = 'orchid';

      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarY.parent = chart.leftAxesContainer;
      chart.scrollbarY.toBack();

      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'date';
      series.strokeWidth = 1;
      series.yAxis = valueAxis;
      series.name = name;
      series.tooltipText = '{name}: [bold]{valueY}[/]';
      series.tensionX = 0.8;
      series.showOnInit = true;
      series.stroke = am4core.color(color);

      const interfaceColors = new am4core.InterfaceColorSet();

      if (t === 1) {
        switch (bullet) {
          case 'triangle':
            const bullet1 = series.bullets.push(new am4charts.Bullet());
            bullet1.width = 10;
            bullet1.height = 10;
            bullet1.horizontalCenter = 'middle';
            bullet1.verticalCenter = 'middle';

            const triangle = bullet1.createChild(am4core.Triangle);
            triangle.stroke = interfaceColors.getFor('background');
            triangle.strokeWidth = 1;
            triangle.direction = 'top';
            triangle.width = 10;
            triangle.height = 10;
            break;
          case 'rectangle':
            const bullet2 = series.bullets.push(new am4charts.Bullet());
            bullet2.width = 10;
            bullet2.height = 10;
            bullet2.horizontalCenter = 'middle';
            bullet2.verticalCenter = 'middle';

            const rectangle = bullet2.createChild(am4core.Rectangle);
            rectangle.stroke = interfaceColors.getFor('background');
            rectangle.strokeWidth = 1;
            rectangle.width = 10;
            rectangle.height = 10;
            break;
          default:
            const bullet3 = series.bullets.push(new am4charts.CircleBullet());
            bullet3.circle.stroke = interfaceColors.getFor('background');
            bullet3.circle.strokeWidth = 1;
            break;
        }
      }

      valueAxis.renderer.line.strokeOpacity = 1;
      valueAxis.renderer.line.strokeWidth = 1;
      valueAxis.renderer.line.stroke = series.stroke;
      valueAxis.renderer.labels.template.fill = series.stroke;
      valueAxis.renderer.opposite = opposite;
      // valueAxis.title.text = "Spo2";
    }
    createAxisAndSeries('spo2', 'spo2', false, 'circle', 1);

    createAxisAndSeries('pulserate', 'pulserate', true, 'circle', 1);

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color('black');
    // Add cursor
    chart.cursor = new am4charts.XYCursor();

  }

  PIGraphFun(response_data) {
    const chart = am4core.create('pichart', am4charts.XYChart);
    // Increase contrast by taking evey second color
    chart.colors.step = 2;
    this.chartData2 = [];

    // Add data
    this.chunkPIData(response_data, () => {
      chart.data = this.chartData2;
    });
    // chart.data = generateChartData();
    // let axisData = [20,22,24,26,28,30,32,34,36,38,40,44];
    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.title.text = "Date & Time";
    dateAxis.renderer.minGridDistance = 60;

    dateAxis.renderer.labels.template.fill = am4core.color('black');
    //Create series
    const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.title.text = "PI"
    valueAxis1.min = 1;
    valueAxis1.max = 200;

    function createAxisAndSeries(field, name, opposite, bullet, t) {
      let valueAxis;
      let color = '';

      valueAxis = valueAxis1;
      color = 'orchid';


      // if(chart.yAxes.indexOf(valueAxis) != 0){
      // 	valueAxis.syncWithAxis = chart.yAxes.getIndex(0);


      // }

      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'date';
      series.strokeWidth = 1;
      series.yAxis = valueAxis;
      series.name = name;
      series.tooltipText = '{name}: [bold]{valueY}[/]';
      series.tensionX = 0.8;
      series.showOnInit = true;
      series.stroke = am4core.color(color);

      const interfaceColors = new am4core.InterfaceColorSet();

      if (t === 1) {
        switch (bullet) {
          case 'triangle':
            const bullet1 = series.bullets.push(new am4charts.Bullet());
            bullet1.width = 10;
            bullet1.height = 10;
            bullet1.horizontalCenter = 'middle';
            bullet1.verticalCenter = 'middle';

            const triangle = bullet1.createChild(am4core.Triangle);
            triangle.stroke = interfaceColors.getFor('background');
            triangle.strokeWidth = 1;
            triangle.direction = 'top';
            triangle.width = 10;
            triangle.height = 10;
            break;
          case 'rectangle':
            const bullet2 = series.bullets.push(new am4charts.Bullet());
            bullet2.width = 10;
            bullet2.height = 10;
            bullet2.horizontalCenter = 'middle';
            bullet2.verticalCenter = 'middle';

            const rectangle = bullet2.createChild(am4core.Rectangle);
            rectangle.stroke = interfaceColors.getFor('background');
            rectangle.strokeWidth = 1;
            rectangle.width = 10;
            rectangle.height = 10;
            break;
          default:
            const bullet3 = series.bullets.push(new am4charts.CircleBullet());
            bullet3.circle.stroke = interfaceColors.getFor('background');
            bullet3.circle.strokeWidth = 1;
            break;
        }
      }

      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarY.parent = chart.leftAxesContainer;
      chart.scrollbarY.toBack();

      valueAxis.renderer.line.strokeOpacity = 1;
      valueAxis.renderer.line.strokeWidth = 1;
      valueAxis.renderer.line.stroke = series.stroke;
      valueAxis.renderer.labels.template.fill = series.stroke;
      valueAxis.renderer.opposite = opposite;

    }
    createAxisAndSeries('pivalue', 'pivalue', false, 'circle', 1);
    // createAxisAndSeries('HRvalue', 'HRvalue', false, 'circle', 1);
    // createAxisAndSeries('Weightvalue', 'Weightvalue', false, 'circle', 1);
    // createAxisAndSeries('RRvalue', 'RRvalue', false, 'circle', 1);
    // createAxisAndSeries('sysvalue', 'sysvalue', false, 'circle', 1);
    // createAxisAndSeries('diavalue', 'diavalue', true, 'circle', 1);
    //createAxisAndSeries('pulserate', 'pulserate', true, 'circle', 1);

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color('black');
    // Add cursor
    chart.cursor = new am4charts.XYCursor();

  }

  HRGraphFun(response_data) {
    const chart = am4core.create('hrchart', am4charts.XYChart);
    // Increase contrast by taking evey second color
    chart.colors.step = 2;
    this.chartData3 = [];

    // Add data
    this.chunkHRData(response_data, () => {
      chart.data = this.chartData3;
    });
    // chart.data = generateChartData();
    // let axisData = [20,22,24,26,28,30,32,34,36,38,40,44];
    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.title.text = "Date & Time";
    dateAxis.renderer.minGridDistance = 60;

    dateAxis.renderer.labels.template.fill = am4core.color('black');
    //Create series
    const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.title.text = "HR"
    valueAxis1.min = 1;
    valueAxis1.max = 200;

    function createAxisAndSeries(field, name, opposite, bullet, t) {
      let valueAxis;
      let color = '';

      valueAxis = valueAxis1;
      color = 'orchid';


      // if(chart.yAxes.indexOf(valueAxis) != 0){
      // 	valueAxis.syncWithAxis = chart.yAxes.getIndex(0);


      // }

      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'date';
      series.strokeWidth = 1;
      series.yAxis = valueAxis;
      series.name = name;
      series.tooltipText = '{name}: [bold]{valueY}[/]';
      series.tensionX = 0.8;
      series.showOnInit = true;
      series.stroke = am4core.color(color);

      const interfaceColors = new am4core.InterfaceColorSet();

      if (t === 1) {
        switch (bullet) {
          case 'triangle':
            const bullet1 = series.bullets.push(new am4charts.Bullet());
            bullet1.width = 10;
            bullet1.height = 10;
            bullet1.horizontalCenter = 'middle';
            bullet1.verticalCenter = 'middle';

            const triangle = bullet1.createChild(am4core.Triangle);
            triangle.stroke = interfaceColors.getFor('background');
            triangle.strokeWidth = 1;
            triangle.direction = 'top';
            triangle.width = 10;
            triangle.height = 10;
            break;
          case 'rectangle':
            const bullet2 = series.bullets.push(new am4charts.Bullet());
            bullet2.width = 10;
            bullet2.height = 10;
            bullet2.horizontalCenter = 'middle';
            bullet2.verticalCenter = 'middle';

            const rectangle = bullet2.createChild(am4core.Rectangle);
            rectangle.stroke = interfaceColors.getFor('background');
            rectangle.strokeWidth = 1;
            rectangle.width = 10;
            rectangle.height = 10;
            break;
          default:
            const bullet3 = series.bullets.push(new am4charts.CircleBullet());
            bullet3.circle.stroke = interfaceColors.getFor('background');
            bullet3.circle.strokeWidth = 1;
            break;
        }
      }

      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarY.parent = chart.leftAxesContainer;
      chart.scrollbarY.toBack();

      valueAxis.renderer.line.strokeOpacity = 1;
      valueAxis.renderer.line.strokeWidth = 1;
      valueAxis.renderer.line.stroke = series.stroke;
      valueAxis.renderer.labels.template.fill = series.stroke;
      valueAxis.renderer.opposite = opposite;

    }
    // createAxisAndSeries('pivalue', 'pivalue', false, 'circle', 1);
    createAxisAndSeries('HRvalue', 'HRvalue', false, 'circle', 1);
    // createAxisAndSeries('Weightvalue', 'Weightvalue', false, 'circle', 1);
    // createAxisAndSeries('RRvalue', 'RRvalue', false, 'circle', 1);
    // createAxisAndSeries('sysvalue', 'sysvalue', false, 'circle', 1);
    // createAxisAndSeries('diavalue', 'diavalue', true, 'circle', 1);
    //createAxisAndSeries('pulserate', 'pulserate', true, 'circle', 1);

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color('black');
    // Add cursor
    chart.cursor = new am4charts.XYCursor();

  }

  NBPGraphFun(response_data) {
    const chart = am4core.create('nbpchart', am4charts.XYChart);
    // Increase contrast by taking evey second color
    chart.colors.step = 2;
    this.chartData4 = [];

    // Add data
    this.chunkNBPData(response_data, () => {
      chart.data = this.chartData4;
    });
    // chart.data = generateChartData();
    // let axisData = [20,22,24,26,28,30,32,34,36,38,40,44];
    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.title.text = "Date & Time";
    dateAxis.renderer.minGridDistance = 60;

    dateAxis.renderer.labels.template.fill = am4core.color('black');
    //Create series
    const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.title.text = "NBP(Sys/Die)"
    valueAxis1.min = 1;
    valueAxis1.max = 200;

    function createAxisAndSeries(field, name, opposite, bullet, t) {
      let valueAxis;
      let color = '';

      valueAxis = valueAxis1;
      color = 'orchid';


      // if(chart.yAxes.indexOf(valueAxis) != 0){
      // 	valueAxis.syncWithAxis = chart.yAxes.getIndex(0);


      // }

      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'date';
      series.strokeWidth = 1;
      series.yAxis = valueAxis;
      series.name = name;
      series.tooltipText = '{name}: [bold]{valueY}[/]';
      series.tensionX = 0.8;
      series.showOnInit = true;
      series.stroke = am4core.color(color);

      const interfaceColors = new am4core.InterfaceColorSet();

      if (t === 1) {
        switch (bullet) {
          case 'triangle':
            const bullet1 = series.bullets.push(new am4charts.Bullet());
            bullet1.width = 10;
            bullet1.height = 10;
            bullet1.horizontalCenter = 'middle';
            bullet1.verticalCenter = 'middle';

            const triangle = bullet1.createChild(am4core.Triangle);
            triangle.stroke = interfaceColors.getFor('background');
            triangle.strokeWidth = 1;
            triangle.direction = 'top';
            triangle.width = 10;
            triangle.height = 10;
            break;
          case 'rectangle':
            const bullet2 = series.bullets.push(new am4charts.Bullet());
            bullet2.width = 10;
            bullet2.height = 10;
            bullet2.horizontalCenter = 'middle';
            bullet2.verticalCenter = 'middle';

            const rectangle = bullet2.createChild(am4core.Rectangle);
            rectangle.stroke = interfaceColors.getFor('background');
            rectangle.strokeWidth = 1;
            rectangle.width = 10;
            rectangle.height = 10;
            break;
          default:
            const bullet3 = series.bullets.push(new am4charts.CircleBullet());
            bullet3.circle.stroke = interfaceColors.getFor('background');
            bullet3.circle.strokeWidth = 1;
            break;
        }
      }

      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarY.parent = chart.leftAxesContainer;
      chart.scrollbarY.toBack();

      valueAxis.renderer.line.strokeOpacity = 1;
      valueAxis.renderer.line.strokeWidth = 1;
      valueAxis.renderer.line.stroke = series.stroke;
      valueAxis.renderer.labels.template.fill = series.stroke;
      valueAxis.renderer.opposite = opposite;

    }
    // createAxisAndSeries('pivalue', 'pivalue', false, 'circle', 1);
    // createAxisAndSeries('HRvalue', 'HRvalue', false, 'circle', 1);
    // createAxisAndSeries('Weightvalue', 'Weightvalue', false, 'circle', 1);
    // createAxisAndSeries('RRvalue', 'RRvalue', false, 'circle', 1);
    createAxisAndSeries('sysvalue', 'sysvalue', false, 'circle', 1);
    createAxisAndSeries('diavalue', 'diavalue', true, 'circle', 1);
    //createAxisAndSeries('pulserate', 'pulserate', true, 'circle', 1);

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color('black');
    // Add cursor
    chart.cursor = new am4charts.XYCursor();

  }


  RRGraphFun(response_data) {
    const chart = am4core.create('rrchart', am4charts.XYChart);
    // Increase contrast by taking evey second color
    chart.colors.step = 2;
    this.chartData5 = [];

    // Add data
    this.chunkRRData(response_data, () => {
      chart.data = this.chartData5;
    });
    // chart.data = generateChartData();
    // let axisData = [20,22,24,26,28,30,32,34,36,38,40,44];
    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.title.text = "Date & Time";
    dateAxis.renderer.minGridDistance = 60;

    dateAxis.renderer.labels.template.fill = am4core.color('black');
    //Create series
    const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.title.text = "RR"
    valueAxis1.min = 1;
    valueAxis1.max = 200;

    function createAxisAndSeries(field, name, opposite, bullet, t) {
      let valueAxis;
      let color = '';

      valueAxis = valueAxis1;
      color = 'orchid';


      // if(chart.yAxes.indexOf(valueAxis) != 0){
      // 	valueAxis.syncWithAxis = chart.yAxes.getIndex(0);


      // }

      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'date';
      series.strokeWidth = 1;
      series.yAxis = valueAxis;
      series.name = name;
      series.tooltipText = '{name}: [bold]{valueY}[/]';
      series.tensionX = 0.8;
      series.showOnInit = true;
      series.stroke = am4core.color(color);

      const interfaceColors = new am4core.InterfaceColorSet();

      if (t === 1) {
        switch (bullet) {
          case 'triangle':
            const bullet1 = series.bullets.push(new am4charts.Bullet());
            bullet1.width = 10;
            bullet1.height = 10;
            bullet1.horizontalCenter = 'middle';
            bullet1.verticalCenter = 'middle';

            const triangle = bullet1.createChild(am4core.Triangle);
            triangle.stroke = interfaceColors.getFor('background');
            triangle.strokeWidth = 1;
            triangle.direction = 'top';
            triangle.width = 10;
            triangle.height = 10;
            break;
          case 'rectangle':
            const bullet2 = series.bullets.push(new am4charts.Bullet());
            bullet2.width = 10;
            bullet2.height = 10;
            bullet2.horizontalCenter = 'middle';
            bullet2.verticalCenter = 'middle';

            const rectangle = bullet2.createChild(am4core.Rectangle);
            rectangle.stroke = interfaceColors.getFor('background');
            rectangle.strokeWidth = 1;
            rectangle.width = 10;
            rectangle.height = 10;
            break;
          default:
            const bullet3 = series.bullets.push(new am4charts.CircleBullet());
            bullet3.circle.stroke = interfaceColors.getFor('background');
            bullet3.circle.strokeWidth = 1;
            break;
        }
      }

      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarY.parent = chart.leftAxesContainer;
      chart.scrollbarY.toBack();

      valueAxis.renderer.line.strokeOpacity = 1;
      valueAxis.renderer.line.strokeWidth = 1;
      valueAxis.renderer.line.stroke = series.stroke;
      valueAxis.renderer.labels.template.fill = series.stroke;
      valueAxis.renderer.opposite = opposite;

    }
    // createAxisAndSeries('pivalue', 'pivalue', false, 'circle', 1);
    // createAxisAndSeries('HRvalue', 'HRvalue', false, 'circle', 1);
    // createAxisAndSeries('Weightvalue', 'Weightvalue', false, 'circle', 1);
    createAxisAndSeries('RRvalue', 'RRvalue', false, 'circle', 1);
    // createAxisAndSeries('sysvalue', 'sysvalue', false, 'circle', 1);
    // createAxisAndSeries('diavalue', 'diavalue', true, 'circle', 1);
    //createAxisAndSeries('pulserate', 'pulserate', true, 'circle', 1);

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color('black');
    // Add cursor
    chart.cursor = new am4charts.XYCursor();

  }

  chunkdeltatdata(dataArray, callback) {
    let chunkArray = dataArray.splice(0, 400);
    if (chunkArray.length > 0) {
      chunkArray.forEach(ele => {
        this.chartData.push({
          date: new Date(ele.lastupdatetime),
          value: ele.deltat

        })
      })
      this.chunkdeltatdata(dataArray, callback);
    } else {
      callback();
    }
  }

  deltaTGraph(response_data) {


    const chart = am4core.create('deltaTTrend', am4charts.XYChart);
    chart.colors.step = 2;
    this.chartData = [];
    // Add data
    this.chunkdeltatdata(response_data, () => {
      chart.data = this.chartData;
    });



    // Set input format for the dates
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd';


    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 60;
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis1.min = 1;
    // valueAxis1.max = 60;

    valueAxis.title.text = 'Delta T(°C)';
    dateAxis.title.text = 'Date & Time';
    valueAxis.title.fill = am4core.color('black');
    dateAxis.title.fill = am4core.color('black');
    dateAxis.renderer.labels.template.fill = am4core.color('black');
    valueAxis.renderer.labels.template.fill = am4core.color('black');
    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.dateX = 'date';
    series.tooltipText = '{value}';
    series.strokeWidth = 1;
    series.minBulletDistance = 20;

    // Drop-shaped tooltips
    series.tooltip.background.cornerRadius = 20;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.pointerOrientation = 'vertical';
    series.tooltip.label.minWidth = 40;
    series.tooltip.label.minHeight = 40;
    series.tooltip.label.textAlign = 'middle';
    // tslint:disable-next-line: deprecation
    series.tooltip.label.textValign = 'middle';

    // Make bullets grow on hover
    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.strokeWidth = 2;
    bullet.circle.radius = 4;
    bullet.circle.fill = am4core.color('#fff');

    const bullethover = bullet.states.create('hover');
    bullethover.properties.scale = 1.3;

    // Make a panning cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = 'panXY';
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = series;

    // Create vertical scrollbar and place it before the value axis
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.parent = chart.leftAxesContainer;
    chart.scrollbarY.toBack();
    chart.scrollbarX = new am4core.Scrollbar();

    dateAxis.start = 0.79;
    dateAxis.keepSelection = true;
  }

  chunkweightData(dataArray, callback) {
    let chunkArray = dataArray.splice(0, 400);
    if (chunkArray.length > 0) {
      chunkArray.forEach(ele => {
        this.chartData.push({
          date: new Date(ele.lastupdatetime),
          value: ele.weightvalue

        })
      })
      this.chunkweightData(dataArray, callback);
    } else {
      callback();
    }
  }

  weightGraph(response_data) {
    // Create chart instance
    const chart = am4core.create('weightgraph', am4charts.XYChart);
    chart.colors.step = 2;
    this.chartData = [];
    // Add data
    this.chunkweightData(response_data, () => {
      chart.data = this.chartData;
    });

    this.weightData = chart.data;
    this.statweight(chart.data);



    // Set input format for the dates
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd';

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 60;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    valueAxis.title.text = 'Weight(Kg)';
    dateAxis.title.text = 'Date & Time';
    valueAxis.title.fill = am4core.color('black');
    dateAxis.title.fill = am4core.color('black');
    dateAxis.renderer.labels.template.fill = am4core.color('black');
    valueAxis.renderer.labels.template.fill = am4core.color('black');
    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.dateX = 'date';
    series.tooltipText = '{value}';
    series.strokeWidth = 1;
    series.minBulletDistance = 20;

    // Drop-shaped tooltips
    series.tooltip.background.cornerRadius = 20;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.pointerOrientation = 'vertical';
    series.tooltip.label.minWidth = 40;
    series.tooltip.label.minHeight = 40;
    series.tooltip.label.textAlign = 'middle';
    // tslint:disable-next-line: deprecation
    series.tooltip.label.textValign = 'middle';

    // Make bullets grow on hover
    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.strokeWidth = 1;
    bullet.circle.radius = 4;
    bullet.circle.fill = am4core.color('#fff');

    const bullethover = bullet.states.create('hover');
    bullethover.properties.scale = 1.3;

    // Make a panning cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = 'panXY';
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = series;

    // Create vertical scrollbar and place it before the value axis
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.parent = chart.leftAxesContainer;
    chart.scrollbarY.toBack();

    // Create a horizontal scrollbar with previe and place it underneath the date axis
    // chart.scrollbarX = new am4charts.XYChartScrollbar();
    // // chart.scrollbarX.series.push(series);
    // chart.scrollbarX.parent = chart.bottomAxesContainer;
    // chart.scrollbarX.toBack();
    // Add scrollbar
    chart.scrollbarX = new am4core.Scrollbar();

    dateAxis.start = 0.79;
    dateAxis.keepSelection = true;
  }

  //delta

  apgarScoreTrend() {
    // Create chart instance
    const chart = am4core.create('apgarTrend', am4charts.XYChart);
    chart.colors.step = 2;

    const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.min = 1;
    valueAxis1.max = 60;

    // Add data
    chart.data = [{
      date: '2012-07-27',
      value: 1.2
    }, {
      date: '2012-07-28',
      value: 1.3
    }, {
      date: '2012-07-29',
      value: 1.5
    }, {
      date: '2012-07-30',
      value: 1.6
    }, {
      date: '2012-07-31',
      value: 1.8
    }, {
      date: '2012-08-01',
      value: 1.3
    }, {
      date: '2012-08-02',
      value: 2.2
    }, {
      date: '2012-08-03',
      value: 2.3
    }, {
      date: '2012-08-04',
      value: 2.0
    }, {
      date: '2012-08-05',
      value: 1.7
    }, {
      date: '2012-08-06',
      value: 1.6
    }, {
      date: '2012-08-07',
      value: 1.8
    }, {
      date: '2012-08-08',
      value: 2.1
    }, {
      date: '2012-08-09',
      value: 2.6
    }, {
      date: '2012-08-10',
      value: 2.4
    }, {
      date: '2012-08-11',
      value: 2.9
    }, {
      date: '2012-08-12',
      value: 3.2
    }, {
      date: '2012-08-13',
      value: 1.8
    }, {
      date: '2012-08-14',
      value: 2.4
    }, {
      date: '2012-08-15',
      value: 2.2
    }, {
      date: '2012-08-16',
      value: 1.8
    },];

    // Set input format for the dates
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd';

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());


    valueAxis.title.text = 'Weight';
    dateAxis.title.text = 'Date & Time';
    valueAxis.title.fill = am4core.color('black');
    dateAxis.title.fill = am4core.color('black');
    dateAxis.renderer.labels.template.fill = am4core.color('black');
    valueAxis.renderer.labels.template.fill = am4core.color('black');
    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.dateX = 'date';
    series.tooltipText = '{value}';
    series.strokeWidth = 6;
    series.minBulletDistance = 15;

    // Drop-shaped tooltips
    series.tooltip.background.cornerRadius = 20;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.pointerOrientation = 'vertical';
    series.tooltip.label.minWidth = 40;
    series.tooltip.label.minHeight = 40;
    series.tooltip.label.textAlign = 'middle';
    // tslint:disable-next-line: deprecation
    series.tooltip.label.textValign = 'middle';

    // Make bullets grow on hover
    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.strokeWidth = 2;
    bullet.circle.radius = 4;
    bullet.circle.fill = am4core.color('#fff');

    const bullethover = bullet.states.create('hover');
    bullethover.properties.scale = 1.3;

    // Make a panning cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = 'panXY';
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = series;

    // Create vertical scrollbar and place it before the value axis
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.parent = chart.leftAxesContainer;
    chart.scrollbarY.toBack();

    // Create a horizontal scrollbar with previe and place it underneath the date axis
    // chart.scrollbarX = new am4charts.XYChartScrollbar();
    // // chart.scrollbarX.series.push(series);
    // chart.scrollbarX.parent = chart.bottomAxesContainer;
    // chart.scrollbarX.toBack();
    // Add scrollbar
    chart.scrollbarX = new am4core.Scrollbar();

    dateAxis.start = 0.79;
    dateAxis.keepSelection = true;

  }

  showFullScreen(){
  const trendsGraphs = document.querySelector("#trendsGraphs");
    trendsGraphs.requestFullscreen()
      .then(()=>{
        this.isFullScreenEnabled = true;
      })
      .catch((error)=> {
        this.isFullScreenEnabled = false;
      });
  }

  showNormalScreen(){
    document.exitFullscreen()
      .then(() => {
        this.isFullScreenEnabled = false;
      })
      .catch((error) => {
        this.isFullScreenEnabled = true;
      });
  }

}


class trends_form {
  machinename: String;
  perametername: String;
  duration: String;
}






/*
-----------------------------spo2 and pulserate function  code -----------------------
if((this.trendsform.machinename == null || this.trendsform.machinename == undefined) && (this.trendsform.duration == null || this.trendsform.duration == undefined)){
                this.filter_msg = "Please Select Proper Warmer Id or Period";
              }else {
                if(this.trendsform.duration == "Todays"){
                  //
                  let obj = {
                    "machineid": Number(this.trendsform.machinename),
                    "fromdate": moment(new Date()).format('YYYY-MM-DD'),
                    "todate": moment(new Date()).format('YYYY-MM-DD')
                  }

                  this.mainserviceService.getspoandpulserate(obj).subscribe((res) => {


                    if(res.status_code == "s_407")
                    {
                      // this.spo2_show  = true;
                      this.msg = '';

                      this.spo2GraphFun(res && res.data && res.data.length > 0 ? res.data : []);

                      }else if(res.status_code == "s_408"){
                        //return res.data;

                        this.msg = this.trendsform.duration+' Spo2 and Pulserate Data does not Found';
                      }else{
                      //return "Data Does Not Found";
                      this.msg = 'Something Wrong';

                      }
                    }, (err) => {

                  });
                }else if(this.trendsform.duration == "Yesterday"){

                let obj = {
                  "machineid": Number(this.trendsform.machinename),
                  "fromdate":moment(new Date()).subtract(1,'d').format('YYYY-MM-DD'),
                  "todate": moment(new Date()).subtract(1,'d').format('YYYY-MM-DD')
                }

                this.mainserviceService.getspoandpulserate(obj).subscribe((res) => {


                  if(res.status_code == "s_407")
                  {
                    this.msg = '';
                    // this.spo2_show  = true;

                    this.spo2GraphFun(res && res.data && res.data.length > 0 ? res.data : [])
                      //return res.data;
                    }else if(res.status_code == "s_408"){
                      //return res.data;
                      this.msg = this.trendsform.duration+' Spo2 and Pulserate Data does not Found';
                    }else{
                    //return "Data Does Not Found";
                    this.msg = 'Something Wrong';

                    }
                  }, (err) => {

                });
                  //This Week
                }else if(this.trendsform.duration == "This Week"){

                let obj = {
                  "machineid": Number(this.trendsform.machinename),
                  "fromdate":moment(new Date()).subtract(7,'d').format('YYYY-MM-DD'),
                  "todate": moment(new Date()).format('YYYY-MM-DD')
                }


                this.mainserviceService.getspoandpulserate(obj).subscribe((res) => {


                  if(res.status_code == "s_407")
                  {
                    this.msg = '';
                    // this.spo2_show  = true;

                    this.spo2GraphFun(res && res.data && res.data.length > 0 ? res.data : [])
                    }else if(res.status_code == "s_408"){
                      //return res.data;
                      this.msg = this.trendsform.duration+' Spo2 and Pulserate Data does not Found';
                    }else{
                    //return "Data Does Not Found";
                    this.msg = 'Something Wrong';

                    }

                  }, (err) => {

                });
                  //This Week
                }else if(this.trendsform.duration == "This Month"){


                let obj = {
                  "machineid": Number(this.trendsform.machinename),
                  "fromdate":moment(new Date()).subtract(30,'d').format('YYYY-MM-DD'),
                  "todate": moment(new Date()).format('YYYY-MM-DD')
                }

                this.mainserviceService.getspoandpulserate(obj).subscribe((res) => {


                  if(res.status_code == "s_407")
                  {
                    // this.spo2_show  = true
                    this.msg = '';
                    this.spo2GraphFun(res && res.data && res.data.length > 0 ? res.data : [])

                    }else if(res.status_code == "s_408"){
                      //return res.data;
                      this.msg = this.trendsform.duration+' Spo2 and Pulserate Data does not Found';
                    }else{
                    //return "Data Does Not Found";
                    this.msg = 'Something Wrong';

                    }

                  }, (err) => {

                });
                  //This Week
                }
              }
*/


/*
----------------------------tempearture Trends data ----------------------------
if((this.trendsform.machinename == null || this.trendsform.machinename == undefined) && (this.trendsform.duration == null || this.trendsform.duration == undefined)){
           this.filter_msg = "Please Select Proper Warmer Id or Period";
         }else {
           if(this.trendsform.duration == "Todays"){


            //  let machineid = parseInt(machine,10);
            let obj = {
              "machineid": Number(this.trendsform.machinename),
              "fromdate": moment(new Date()).format('YYYY-MM-DD'),
              "todate": moment(new Date()).format('YYYY-MM-DD')
            }

            this.mainserviceService.gettempdata(obj).subscribe((res) => {


              if(res.status_code == "s_407")
              {
                this.msg = '';


                this.temperatureGraph(res && res.data && res.data.length > 0 ? res.data : [])
                  //return res.data;
                }else if(res.status_code == "s_408"){
                  //return res.data;

                  this.msg = this.trendsform.duration+' Temperature Data does not Found';
                }else{
                //return "Data Does Not Found";
                this.msg = 'Something Wrong';

                }
              }, (err) => {

            });
           }else if(this.trendsform.duration == "Yesterday"){

            // let dateTo = moment(new Date()).subtract(1,'d').format('YYYY-MM-DD');
            // let dateFrom = moment(new Date()).subtract(1,'d').format('YYYY-MM-DD');




           let obj = {
             "machineid": Number(this.trendsform.machinename),
             "fromdate":moment(new Date()).subtract(1,'d').format('YYYY-MM-DD'),
             "todate": moment(new Date()).subtract(1,'d').format('YYYY-MM-DD')
           }

           this.mainserviceService.gettempdata(obj).subscribe((res) => {


             if(res.status_code == "s_407")
             {
              this.msg = '';
               this.temp_show  = true;

               this.temperatureGraph(res && res.data && res.data.length > 0 ? res.data : [])
                 //return res.data;
               }else if(res.status_code == "s_408"){
                 //return res.data;
                 this.msg = this.trendsform.duration+' Temperature Data does not Found';
               }else{
               //return "Data Does Not Found";
               this.msg = 'Something Wrong';

               }
             }, (err) => {

           });
             //This Week
           }else if(this.trendsform.duration == "This Week"){

            // let dateTo = moment(new Date()).format('YYYY-MM-DD');
            // let dateFrom = moment(new Date()).subtract(7,'d').format('YYYY-MM-DD');




           let obj = {
             "machineid": Number(this.trendsform.machinename),
             "fromdate":moment(new Date()).subtract(7,'d').format('YYYY-MM-DD'),
             "todate": moment(new Date()).format('YYYY-MM-DD')
           }

           this.mainserviceService.gettempdata(obj).subscribe((res) => {


             if(res.status_code == "s_407")
             {
              this.msg = '';
               this.temp_show  = true;

               this.temperatureGraph(res && res.data && res.data.length > 0 ? res.data : [])
                 //return res.data;
               }else if(res.status_code == "s_408"){
                 //return res.data;
                 this.msg = this.trendsform.duration+' Temperature Data does not Found';
               }else{
               //return "Data Does Not Found";
               this.msg = 'Something Wrong';

               }

             }, (err) => {

           });
             //This Week
           }else if(this.trendsform.duration == "This Month"){


           let obj = {
             "machineid": Number(this.trendsform.machinename),
             "fromdate":moment(new Date()).subtract(30,'d').format('YYYY-MM-DD'),
             "todate": moment(new Date()).format('YYYY-MM-DD')
           }

           this.mainserviceService.gettempdata(obj).subscribe((res) => {


             if(res.status_code == "s_407")
             {
              this.msg = '';
               this.temp_show  = true;

               this.temperatureGraph(res && res.data && res.data.length > 0 ? res.data : [])
                 //return res.data;
               }else if(res.status_code == "s_408"){
                 //return res.data;
                 this.msg = this.trendsform.duration+' Temperature Data does not Found';
               }else{
               //return "Data Does Not Found";
               this.msg = 'Something Wrong';

               }

             }, (err) => {

           });
             //This Week
           }
         }
*/
